<template>
   <div class="m-5" id="monthly-feeds">
      <div class="row">
         <div class="col-md-12 pb-2">
            <h2 class="text-center">Monthly Feeds</h2>
         </div>
      </div>
      <div class="row">
         <div class="col-md-8 pe-1">
            <div class="card">
               <div class="card-header">
                  <h4 class="fw-bold my-2">Select Month</h4>
               </div>
               <div class="card-body monthDateSelect">
                  <div class="row">
                   <div class="col-md-6">
                     <input type="month"  @change="onMonthlyFeedSelect" v-model="selected_month" class="form-select monthSelect" :min="minMonth"  @click.stop>
                     <span class="error-message"> {{errorMessage}}</span>
                   </div>
                  <div class="col-md-6">
                     <input type="text" class="form-control" v-model="MonthlyFeedsSearch" placeholder="Search" @input="onMonthlyFeedSelect" :disabled="isFilterEnable">
                  </div>
                  </div>                  
               </div>
               <div class="card-body">
                  <div class="row">                   
                     <div class="col-md-6">
                        <select  v-model="MonthlyFeedsFilter" @change="onMonthlyFeedSelect" class="form-select" :disabled="isFilterEnable" >
                           <option value="">All</option>
                           <option v-for="(feedType, index) of feedTypes" :key="index" :value="feedType.type">
                              {{ feedType.type }}
                           </option>
                        </select>
                     </div>                     
                     <div class="col-md-6">
                        <select v-model="perPage" @change="onMonthlyFeedSelect" class="form-select" :disabled="isFilterEnable" >
                           <option value="10">10</option>
                           <option value="25">25</option>
                           <option value="50">50</option>
                           <option value="100">100</option>
                           <option value="All">All</option>
                        </select>
                     </div>
                  </div>
               </div>
            </div>
            <div class="card" >
               <div class="card-header d-flex justify-content-between align-items-center">
                  <div>
                     <h5 class="my-2">Select Feeds</h5>
                     <small class="text-secondary small">Please select month to enable feeds selection.</small>
                  </div>
                  <span class="ms-auto">
                     <p class="text-danger small" v-if="schedulingInProgress" >Feeds are saving. Please wait & refresh feeds after sometime  <a href="javascript:void(0);" @click="refreshPage()"><i  class="fa fa-spinner btn fs-5 p-0 text-primary" 
                       > 
                        </i></a>
                     </p>
                  </span>
               </div>
               <div class="loadingList" :style="{ overflow: loading ? 'hidden' : 'auto' }"> 
                  <div class="d-flex justify-content-center align-items-center h-100 w-100 position-absolute" v-if="loading" >
                       <span class="spinner-grow spinner-grow-sm text-primary" role="status" aria-hidden="true"></span>
                Loading..
                  </div>              
               <div v-if="this.selected_month" :class="{ 'disable': loading }" 
                   :style="{ opacity: loading ? 0.5 : 1 }" >   
                  <div v-if="displayedRecords.length > 0">               
                        <div class="card-body"  v-for="(monthlyFeed, index) in displayedRecords" :key="index">
                           <div class="card-header" >
                              <div class="d-md-flex justify-content-between">
                                 <div class="left w-50">
                                    <div :id="`monthlyFeed_Title_${monthlyFeed.id}`" class="small">
                                       {{ monthlyFeed.title }}
                                    </div>
                                    <span class="small text-capitalize"><strong>Type: </strong> {{ monthlyFeed.type }}</span>
                                 </div>
                                 <div 
                                    class="right w-50 d-md-flex align-items-center gap-2 justify-content-end" 
                                    v-if="!monthlyFeed.scheduled_monthly_feed">
                                    <div class="first py-2 py-md-0" v-if="!schedulingInProgress" >
                                       <label
                                          role="button"
                                          :class="isFeedSelected(monthlyFeed.id) ? 'text-danger p-0 mt-1' : 'text-success p-0 mt-1'"
                                          @click="toggleFeedSelection(monthlyFeed.id)"
                                          >
                                       <i v-if="isFeedSelected(monthlyFeed.id)" class="bi bi-dash-circle fs-5"></i>
                                       <i v-else class="bi bi-plus-circle fs-5"></i>
                                       </label>
                                    </div>
                                    <div class="second DateSelect" v-if="!schedulingInProgress">
                                       <input
                                          class="form-control monthly_date w-auto"
                                          :id="`monthlyFeed_${monthlyFeed.id}`"
                                          :type="isFeedSelected(monthlyFeed.id) ? 'date' : 'text'"
                                          @change="onScheduleDateSelect($event.target.value, monthlyFeed.id, index)"
                                          :disabled="!isFeedSelected(monthlyFeed.id)"
                                          :value="getFeedDate(monthlyFeed.id)"
                                          placeholder="Schedule Date"
                                          :min="SelectedMinDate"
                                          :max="SelectedMaxDate"
                                          />
                                    </div>
                                    <div class="third pt-2 pt-md-0" v-if="!schedulingInProgress">
                                       <select
                                          class="form-select monthly_time w-auto"
                                          @change="onScheduleTimeSelect($event.target.value, monthlyFeed.id, index)"
                                          :disabled="!isFeedSelected(monthlyFeed.id)"
                                          >
                                          <option value="" v-if="!isFeedSelected(monthlyFeed.id)" selected>Hour</option>
                                          <option v-for="hour in feedTime" :key="hour.id" :value="hour.value">{{ hour.text }}</option>
                                       </select>
                                    </div>
                                 </div>
                                 <div class="right w-50 d-md-flex align-items-center gap-2 justify-content-end" v-else><span class="info-text text-capitalize badge bg-success rounded-pill small mt-1"> Already Scheduled </span></div>
                              </div>
                           </div>
                        </div>
                     </div> 
                   <div class="card-body" v-else>
                      <p>No Feeds Found For Selected Month</p>
                   </div>
               </div>
               <div class="card-body" v-else>
                  <p>Please Select  Month to Show Monthly Feeds.</p>
               </div>
               </div>
               <div class="d-flex justify-content-end mb-5 flex-wrap" v-if="perPage!='All'">           
               <!-- Pagination and Monthly Feed Info -->
               <div class="d-flex justify-content-end mb-5 flex-wrap">
                  <p class="text-nowrap pages_count text-secondary mb-0" v-if="total">
                  Monthly Feed
                  <span class="fw-bold"><strong>{{ monthlyFeedsList.from }}</strong></span> to
                  <span class="fw-bold"><strong>{{ monthlyFeedsList.to }}</strong></span> out of
                  <span class="fw-bold">{{ total }}</span>
                  </p>
                  <!-- Paginate Component -->
                  <paginate
                  v-if="displayedRecords.length > 0"
                  :class="paginateDisable ? 'custom-pagination-disabled' : ''"
                  v-model.lazy="page"
                  :page-count="lastPage"
                  :page-range="3"
                  :click-handler="paginationCallback"
                  :disabled-class="'pagination-disabled'"
                  :container-class="'VuePagination__pagination pagination VuePagination__pagination m-0 px-2'"
                  :page-class="'VuePagination__pagination-item page-item'"
                  :page-link-class="'page-link shadow-none fw-bold'"
                  :prev-link-class="'page-link shadow-none'"
                  :next-link-class="'page-link shadow-none'"
                  :prev-text="`<i class='bi bi-chevron-left icon-bold'></i>`"
                  :next-text="`<i class='bi bi-chevron-right icon-bold'></i>`"
                  />
               </div>
            </div>
            </div>  
         </div>
         <div class="col-md-4 ps-1">
            <div class="d-flex justify-content-between align-items-center">              
               <div class="lt flex-fill">
                  <button class="btn btn-primary w-100" :data-bs-toggle="isScheduleModalVisible ? 'modal' : 'disabled'" 
                  data-bs-target="#monthlyFeedScheduleModal" @click="monthlyFeedScheduleModal()"> Schedule Monthly Feed </button>
               </div>
                <div class="rt">
                  <a class="btn btn-primary mx-2" data-bs-toggle="modal"  data-bs-target="#CalendarModal"   href="javascript:;"   v-tooltip="'Upcoming Feeds'">
                     <i class="fa fa-calendar"></i>
                  </a>
                  <a class="btn btn-primary"          
                     href="javascript:void(0);"
                    v-tooltip="'Monthly Feed History'"
                    :data-bs-toggle="isScheduledFeedVisible ? 'modal' : 'disabled'"  
                     data-bs-target="#scheduledFeedModal"                    
                     @click="scheduledFeedModal()">
                     <i class="fa fa-clipboard-list"></i>
                  </a>
               </div>
            </div>
           
            <div class="card mt-2">
               <div class="card-header">
                  <div class="d-flex justify-content-between align-items-center">
                     <h5 class="fw-bold my-2 d-inline">Selected Feeds   <span  class="fw-bold" v-if="scheduledDateTime.length>1">({{ scheduledDateTime.length }})</span></h5>
                     <a href="javascript:void(0)" @click="removeAllItem()" v-if="scheduledDateTime.length>1">
                     <i class="fas fa-trash text-danger"></i>
                     </a>
                  </div>
                  <small class="text-secondary small">Only selected feeds for scheduling will be shown here.</small>                  
               </div>
               <div class="card-body"  v-for="(selectedRow, selectMonth) in formattedData" 
                  :key="selectMonth">
                  <div class="card-header bg-secondary text-white rounded-0">
                     <h5 class="fw-bold my-2">{{ selectMonth}}</h5>
                  </div>
                  <div 
                     class="card mb-2 p-2 border-0 shadow rounded-0" 
                     v-for="(feedItem, index) in selectedRow" :key="index"  >
                     <div>
                        <div class="d-flex align-items-center justify-content-between flex-wrap">                         
                           <label >
                                {{feedItem.data.title }}                          
                           </label>
                           <span role="button" @click="toggleFeedSelection(feedItem.data.id)">
                              <i class="fas fa fa-remove ms-1 text-danger"></i>
                           </span>
                        </div>
                        <span class="info-text text-capitalize badge bg-primary rounded-pill small mt-1">
                        {{ feedItem.data.type }} 
                        </span>
                        <label role="button" data-bs-toggle="modal" data-bs-target="#monthlyFeedModal" @click="showDetails(feedItem)">
                        <i class="fas fa fa-eye fs-5 text-success ms-1"></i>
                        </label>
                     </div>
                     <p><strong>Schedule Date:</strong> {{ feedItem.schedule_date }} {{ dateFormat(`${feedItem.schedule_date} ${feedItem.schedule_time}`) }} <strong> Page:</strong>{{ page }}</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <DetailModal
         :feedItems="selectedFeedItem" 
         :visible="isDetailsVisible"      
         :feedMedia="feedMedia"
         />
      <ScheduleFormModal
         :visible="isScheduleModalVisible"
         :scheduleFeedItem="scheduledDateTime"
         ref="childRef"
         :filterData="filterData" 
         @refreshFeed="refreshFeed"
         @allDisableItem="allDisableItem"   
         />
      <ScheduledFeedModal
         :visible="isScheduledFeedVisible"
         :filterData="filterData" 
         />
      <Calendar 
      :filterData="filterData"       
      />
   </div>
</template>
<script>
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
import Loading from "vue-loading-overlay";
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import "vue-loading-overlay/dist/vue-loading.css";
import DetailModal from './detailModal';
import ScheduleFormModal from './scheduleFormModal';
import ScheduledFeedModal from './scheduledFeedModal';
import Calendar from './calendar';  
import moment from 'moment-timezone'

export default {
name: "customMonthlyFeeds",
   components: {   
      Loading,
      vSelect,
      DetailModal,
      ScheduleFormModal,
      ScheduledFeedModal,   
      Calendar      
   },
   computed: {
   ...mapGetters(["monthlyFeedsList","CalendarFeed"]),
      displayedRecords() { 
         this.total = this.selected_month?parseInt(this.monthlyFeedsList.monthly_feeds?.total):0;
         this.lastPage = parseInt(this.monthlyFeedsList.monthly_feeds?.last_page);   
         this.monthlyFeeds = this.monthlyFeedsList.monthly_feeds?.data || {};
         this.feedTypes = this.monthlyFeedsList.feeds_type || [];        
         this.schedulingInProgress = this.monthlyFeedsList.in_progress?true:false;  
         return this.selected_month?this.monthlyFeeds:{};
      },        
   },       
   data() {
      return {
         filterData: {},
         page: 1,
         perPage: 10,
         total: 0,
         lastPage: 0,
         selected_month: "",        
         MonthlyFeedsSearch: "",
         MonthlyFeedsFilter: "",
         schedulingInProgress:false,   
         feedMedia: "", 
         timeSel: "",         
         errorMessage: "",    
         monthlyFeeds: {},
         selectedFeedItem: {}, 
         scheduledType:{},         
         isScheduleModalVisible: false,
         isScheduledFeedVisible:true,   
         isFilterEnable: true, 
         paginateDisable: false,
         isDetailsVisible: false,
         isCalenderVisible:false,
         months: ["january", "february", "march", "april", "may", "june", 
                     "july", "august", "september", "october", "november", "december"],   
              
         feedTime: [
            { value: '02:00:00', text: '2:00 AM' },
            { value: '04:00:00', text: '4:00 AM' },
            { value: '06:00:00', text: '6:00 AM' },
            { value: '08:00:00', text: '8:00 AM' },
            { value: '10:00:00', text: '10:00 AM' },
            { value: '12:00:00', text: '12:00 PM' },
            { value: '14:00:00', text: '2:00 PM' },
            { value: '16:00:00', text: '4:00 PM' },
            { value: '18:00:00', text: '6:00 PM' },
            { value: '20:00:00', text: '8:00 PM' },
            { value: '22:00:00', text: '10:00 PM' },
         
         ],
         feedTypes: [],   
         selectedFeed: [],        
         scheduledDateTime: [],
         // scheduledDetails:[],    
         SelectedMaxDate: "", 
         SelectedMinDate:"",   
         loading:false,
         error: false,
         paginateDisable: false,
         feedsType: null,        
         FeedDetails: [],
         selectedItems: [],
         feeds: [],
         formattedData: [],
         calendarEvent: [], 
         minMonth: this.getCurrentMonth(),    
        
       };
     },         
      methods: {
       ...mapActions(["fetchMonthlyFeeds","fetchMonthlyFeedHistory","fetchCalendar"]),    
      getCurrentMonth() {
         const today = new Date();
         const year = today.getFullYear();
         let month = today.getMonth() + 1; 
         const isLastDayOfMonth = new Date(year, month, 0).getDate() === today.getDate();
         if (isLastDayOfMonth) {
            month += 1; 
            if (month > 12) {
               month = 1;
               year += 1;
            }
         }
         const formattedYear = year;
         const formattedMonth = month.toString().padStart(2, '0');
         return `${year}-${formattedMonth}`;
      },       
         onMonthlyFeedSelect(event) {         
         const [year, months] = this.selected_month.split("-").map(Number);
         let month = months > 9 ? months : '0' + months;
         if (this.minMonth == this.selected_month) {
           this.SelectedMinDate = new Date(Date.now() + 86400000).toISOString().split('T')[0];
         } else {
           this.SelectedMinDate = year + '-' + month + '-01';
         }     
         this.SelectedMaxDate =  year + '-' + month + '-'+ new Date(year, month, 0).getDate();         
         this.errorMessage = "";     
         var data = { 
            feed_type: "",
            used: "",
            search: "",
            page: this.page,
            perPage: this.perPage,  
            selected_month: this.selected_month,
            search: this.MonthlyFeedsSearch,
            feed_type: this.MonthlyFeedsFilter, 
         };
         this.filterData = data;
         this.error = false;
         this.filterData.page = 1; 
         this.loading = true;  
         this.isFilterEnable = false;
         this.fetchMonthlyFeeds(this.filterData)
            .then(() => { 
            this.isLoading();      
         })
         .catch(() => {
            this.isLoading();   
            this.error = true;
         });
      },      
      paginationCallback(page) {
         //console.log(page)
         this.paginateDisable = true;   
         (this.filterData.page = page), (this.filterData.perPage = this.perPage);   
         this.fetchMonthlyFeeds(this.filterData)
           .then((response) => {
             this.page = parseInt(page);
             this.paginateDisable = false;
           })
           .catch(() => {});
      },
      getFeedDate(id) {
         const entry = this.scheduledDateTime.find((entry) => entry.id === id);
         return entry ? entry.date : '';
       }, 
       getFeedData(id,index) {    
         return this.FeedDetails[id] = this.monthlyFeeds[index];
       }, 
       onScheduleDateSelect(value, id,index) {      
         const entry = this.scheduledDateTime.find((entry) => entry.id === id)      
         const currentTime = this.timeSel ? this.timeSel : this.feedTime[0].text; 
         const rowData = this.getFeedData(id,index);
         if (entry) {
           entry.date = value;
           entry.time = entry.time ? entry.time : currentTime; // Keep the current time
         } else {
           this.scheduledDateTime.push({ id, date: value, time: currentTime, rowData: rowData });
         //   this.scheduledDetails.push({ id, date: value, time: currentTime}); 
         }
         this.getFeedData(id); 
         this.renderPreviewData();
         this.isScheduleModalVisible = true; 
       },
      onScheduleTimeSelect(value, id,index) {
         const entry = this.scheduledDateTime.find((entry) => entry.id === id);
         const currentDate = this.getFeedDate(id); // Preserve the current date 
         const rowData = this.getFeedData(id,index)   
         if (!currentDate) {
           this.timeSel = value;
           return;
         }    
         if (entry) {
           entry.time = value;
           entry.date = currentDate; // Keep the current date
         } else {
           this.scheduledDateTime.push({ id, date: currentDate, time: value, rowData: rowData });  
         //   this.scheduledDetails.push({ id, date: currentDate, time: value}); 

         }
         this.getFeedData(id);
         this.renderPreviewData()  
       },
      isFeedSelected(id) {
         return this.selectedItems.includes(id);
       },
      toggleFeedSelection(id) {
         if (this.isFeedSelected(id)) {
           this.removeItem(id);// If already selected, remove it
         } else {     
           this.selectedItems.push(id); // Otherwise, add it to selected items
         }
       },
      removeItem(id) {        
        const index = this.scheduledDateTime.findIndex((entry) => entry.id === id);        
         if (index !== -1) {
           this.scheduledDateTime.splice(index, 1);
         }
         this.selectedItems = this.selectedItems.filter((itemId) => itemId !== id);
         if (this.scheduledDateTime.length === 0) {
            this.isScheduleModalVisible = false; 
         }
         this.renderPreviewData();
         },
      allDisableItem(id) {        
         const index = this.scheduledDateTime.findIndex((entry) => entry.id === id);    
         this.selectedItems = this.selectedItems.filter((itemId) => itemId !== id);
         this.renderPreviewData();
      },
      removeAllItem() {        
         var confirmation = confirm('Are you sure, you want to remove all selected  feeds?');
            if (confirmation) {
            this.scheduledDateTime.map((event, index) => {
              this.allDisableItem(event.id) 
            });             
         this.refreshFeed();          
         this.renderPreviewData();
          }
           
        },
      showDetails(entry) {   
         if (entry) {
           this.selectedFeedItem = entry.data;
           this.feedMedia = { media: JSON.parse(entry.data.media) };        
         }
         else {
           this.selectedFeedItem = {};
         }   
         this.isDetailsVisible = true; // Show the modal
       }, 
      monthlyFeedScheduleModal() {
         const SeMonth = this.selected_month;        
         if (!SeMonth) {
            this.errorMessage = "Please select a valid month!";
            return;
         }       
          if (this.scheduledDateTime.length === 0) {      
             alert('Please select at least one feed for scheduling');                             
             return;
          }      
         this.selectedFeedItem = this.renderPreviewData();
         this.$refs.childRef.getClients();
         }, 
         
      scheduledFeedModal() {     
         this.fetchMonthlyFeedHistory()                
         this.isScheduledFeedVisible = true;
      },      
      renderPreviewData() {
         var formattedDataObj = {};    
             this.scheduledDateTime.forEach(sc => {
             var date = new Date(sc.date);
             var monthKey = this.months[date.getMonth()].toUpperCase();          
             if (!formattedDataObj[monthKey]) {
               formattedDataObj[monthKey] = []; // Initialize the array if it doesn't exist
             }          
             formattedDataObj[monthKey].push({
               schedule_date: sc.date,
               schedule_time: sc.time,
               data: sc.rowData
             });
           });  
         return this.formattedData = formattedDataObj;
         },
      refreshFeed() {                
         this.scheduledDateTime = [];  
         this.formattedData = [];
         this.isScheduleModalVisible = false;     
      },
      refreshPage() {
          this.fetchMonthlyFeeds(this.filterData); 
         }, 
      isLoading() {
            setTimeout(() => {
            this.loading = false;  
        }, 500); 
      },
      dateFormat:function(date){
         return date ? moment(date).format('hh:mm A') : '--'
      },
   }, 
   
   };
   
</script>
<style>
   html,
   body {
   font-size: 14px;
   }
   .error-message {
   color: #dc3545;
   }   
   .monthDateSelect {
   position: relative;
   }
   .monthDateSelect input{
   padding-left: 25px;
   }
   input.monthSelect[type="month"]::-webkit-calendar-picker-indicator {
   position: absolute;
   top: 10;
   right: -23px;
   width: 100%;
   padding: 0;     
   }
   .DateSelect {
     position: relative;
   }
   .DateSelect input{
     padding-left: 25px;
   }
   input.monthly_date[type="date"]::-webkit-calendar-picker-indicator {
      position: absolute;
      top: 10;
      left: 8px;
      width: 100%;
      padding: 0;     
   }
   .loadingList{
      position: relative;
      max-height: 600px;
   }
  


</style>