<template>
    <div>
        <div class="observance_data w-100 p-0 mb-3">
            <div class="cards-sec card-bg">
                <div class="row m-0 d-flex px-0">

                    <div class="col-md-12 p-3" id="heading">
                        <div class="d-flex justify-content-between">
                            <div class="d-flex justify-content-start">
                                <img :src="localReview.avatar ? localReview.avatar : '/img/user2.8a8f01f3.png'"
                                    style="border-radius: 50%;width: 50px;height: 50px;"
                                    class="img-thumbnail"
                                    alt="avatar"
                                />
                                <div style="margin-left: 10px;">
                                    <div style="font-weight: bold;">{{ localReview.author }}</div>
                                    <div>
                                        <span v-for="n in 5" :key="n" class="star">
                                            {{ n <= localReview.rating ? '★' : '☆' }}
                                        </span>
                                        <img v-if="localReview.platform.toLowerCase() === 'yelp'" src="../../../assets/yelp.png" alt="Yelp" style="width: 20px; margin-left: 5px; margin-bottom: 4px">
                                        <img v-else-if="localReview.platform.toLowerCase() === 'google'" src="../../../assets/google.png" alt="Google" style="width: 20px; margin-left: 5px; margin-bottom: 4px">
                                        <img v-else-if="localReview.platform.toLowerCase() === 'facebook'" src="../../../assets/facebook.png" alt="Facebook" style="width: 20px; margin-left: 5px; margin-bottom: 4px">   
                                    </div>
                                </div>
                            </div>
                            <div>
                                <span v-if="!localReview.review_id"class="badge bg-primary">Custom</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12" id="form">
                        <form @submit.prevent="update">
                            <div class="row m-1 p-1 form-row">
                                <div class="col-md-2">
                                    <div class="mb-1">
                                        <label :for="`author-name-${index}`" class="form-label">Author <span class="required">*</span></label>
                                        <input type="text" :id="`author-name-${index}`" v-model="localReview.author" class="form-control"/>
                                    </div>
                                    <div class="mb-1">
                                        <label :for="`platform-${index}`" class="form-label">Platform <span class="required">*</span></label>
                                        <v-select 
                                            :id="`platform-${index}`"
                                            v-model="localReview.platform"
                                            placeholder="Select Platform"
                                            :options="platforms"
                                            :clearable="false"
                                        ></v-select>
                                    </div>
                                    <div class="mb-1">
                                        <label :for="`rating-${index}`" class="form-label">Rating <span class="required">*</span></label>
                                        <v-select 
                                            label="title"
                                            :id="`rating-${index}`"
                                            v-model="localReview.rating"
                                            :options="ratings"
                                            placeholder="Select Rating"
                                            :reduce="ratings => ratings.id"
                                            :clearable="false"
                                        ></v-select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label :for="`description-${index}`" class="form-label">Description</label>
                                        <textarea @input="updateCount" :maxlength="maxReviewDescription" class="form-control" :id="`description-${index}`" v-model="localReview.text" rows="7"></textarea>
                                        <div class="d-flex justify-content-end">
                                            <small>{{descriptionCount}}/{{maxReviewDescription}}</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="mb-3">
                                        <label :for="`short-description-${index}`" class="form-label">Short Description</label>
                                        <textarea 
                                            @input="updateCount"
                                            class="form-control"
                                            :id="`short-description-${index}`"
                                            v-model="localReview.short_description"
                                            :maxlength="maxReviewShortDescription"
                                            rows="7"
                                            :disabled="descriptionCount > 150 ? false : true"
                                            ></textarea>
                                        <div class="d-flex justify-content-end">
                                            <small>{{shortDescriptionCount}}/{{maxReviewShortDescription}}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" id="buttons">
                                <div class="col-md-12 mt-2 mb-1">
                                    <div class="d-flex justify-content-end">
                                        <div class="me-2 my-1">
                                            <button type="button" @click="moveReviewsDirectStart(localReview.id)" :disabled="isMoveReviewLoading == localReview.id ? true : false" class="me-2 btn-sm btn btn-info text-white" >
                                                {{ converted || localReview.feed && localReview.feed.id ? 'Re-convert' : 'Convert'  }} Reviews To Feed
                                                <Loading v-if="isMoveReviewLoading == localReview.id ? true : false"/>
                                            </button>

                                            <button
                                                type="button"
                                                :dataId="localReview.id"
                                                class="btn-sm btn btn-danger text-light open_delete_modal">
                                                Delete
                                            </button>

                                            <button type="submit" :disabled="isLoading" class="ms-2 btn-sm btn btn-info text-white" >
                                                Update
                                                <Loading v-if="isLoading"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    
                    <div class="col-md-12 divider">
                        <hr>
                    </div>
                    
                    <div class="col-md-12 mb-4" id="central-tags">
                        <label class="form-label">Central Tags</label>
                        <br>
                        <div class="form-check form-check-inline" v-for="(tag, tagIndex) in tags">
                            <input 
                                class="form-check-input"
                                type="checkbox"
                                dataType="tags"
                                :id="`central-tag-${tag.id}-${index}-${tagIndex}`"
                                :dataParentId="localReview.id"
                                :dataId="tag.id"
                                :value="tag.title"
                                :checked="localReview.id && checkedTagsIds[localReview.id] && checkedTagsIds[localReview.id].tags.includes(tag.id) ? true : false"
                                @change="onChangeTag"
                            />
                            <label
                                class="form-check-label"
                                :for="`central-tag-${tag.id}-${index}-${tagIndex}`"
                            >
                                {{ tag.title }}
                            </label>
                        </div>
                    </div>
                    
                    <div class="col-md-12 divider">
                        <hr>
                    </div>
                    
                    <div class="col-md-12 mb-4" id="my-tags">
                        <div class="d-flex justify-content-between">
                            <label class="form-label">My Tags</label>
                            <a class="btn-sm btn btn-primary open_add_tag_modal" href="javascript:;" role="button">Add Tag</a>
                        </div>
                        <div class="form-check form-check-inline" v-for="(tag, tagIndex) in myTags">
                            <input 
                                type="checkbox"
                                class="form-check-input"
                                dataType="tags"
                                :dataParentId="localReview.id"
                                :dataId="tag.id"
                                :value="tag.title"
                                :id="`my-tag-${tag.id}-${index}-${tagIndex}`"
                                :checked="localReview.id && checkedTagsIds[localReview.id] && checkedTagsIds[localReview.id].tags.includes(tag.id) ? true : false"
                                @change="onChangeTag"
                            />
                            <label
                                class="form-check-label"
                                :for="`my-tag-${tag.id}-${index}-${tagIndex}`"
                            >
                                {{ tag.title }}
                            </label>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import Loading from './loading.vue';

export default {
    name: 'Card',
    components: {
        vSelect,
        Loading
    },
    props: {
        tags: Array,
        platforms: Array,
        ratings: Array,
        index: Number,
        myTags: Array,
        review: Object,
        updateReview:Function,
        isLoading:Boolean,
        checkedTagsIds:Object,
        onChangeTag:Function,
        maxReviewDescription:Number,
        maxReviewShortDescription:Number
    },
    data:function(){
        return {
            reviewMoveStatus:0,
            descriptionCount:0,
            shortDescriptionCount:0,
            isMoveReviewLoading:0,
            converted:0,
            localReview:{}
        }
    },
    created:function() {
        this.localReview = this.review
        this.updateCount()
    },
    methods: {
        update:async function(){
            let resp = await this.updateReview(this.localReview)
            if(resp)
            {
                this.review = resp
            }
        },
        updateCount:function(){
            if(this.review)
            {
                this.descriptionCount = this.localReview.text ? this.localReview.text.length : 0
                this.shortDescriptionCount = this.localReview.short_description ? this.localReview.short_description.length : 0
            }
        },
        moveReviewsDirectStart:async function(reviewId, index){
            if(confirm("Are You Sure ?"))
            {
                this.reviewMoveStatus = true;
                let resp = await this.$helper.getRequest(`users/reviews/${reviewId}/move-review-direct`)
                
                if(resp && resp.status)
                {
                    if(resp.review)
                    {
                        /* this.review = resp.review */
                        this.converted = 1
                    }
                    this.notify(resp.message)
                    this.reviewMoveStatus = false;
                }
                else
                {
                    this.reviewMoveStatus = false;
                    this.notify(resp.message,'danger')
                }
            }
        },
        notify:function(msg, type = 'success'){
            this.$toasted.show(msg, {
                type: type,
                theme: 'bubble',
                duration: 2500,
                singleton: true,
                position: 'top-center',
            })
        }
    }
}
</script>

<style>

.is-invalid {
    border: 1px solid red !important;
    border-radius: 5px;
}

.star {
    color: gold;
    font-size: 16px;
}

.card-bg {
    background-color: #ffffff;
    border: 1px solid #cccccc
}

.form-row{
    background-color: #f7f7f7;border: 2px dotted #cccccc
}

</style>