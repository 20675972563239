<template>
  <div>
    <div class="page-content page-container" id="page-content">
      <div class="padding">
        <div class="row container d-flex justify-content-center">
          <div class="col-xl-9 col-md-12">
            <div class="card user-card-full">
              <div class="row m-l-0 m-r-0">
                <!-- <div class="col-sm-4 bg-c-lite-green user-profile">
                  <div class="card-block text-center text-white">
                   
                    <h6 class="f-w-600">{{formFields.first_name+" "+formFields.last_name}}</h6>
                    <p>Subscribed plan detail</p>
                    <p>Charge: ${{planData.amount/100}}/month</p>
                    <p v-if="new Date(subData.trial_end)>new Date()">Free Trail End: {{new Date(subData.trial_end).toLocaleString('en-us',{month:'long', year:'numeric',day:'numeric'})}}</p>
                    <p v-else>Plan End: {{new Date(subData.current_period_end).toLocaleString('en-us',{month:'long', year:'numeric',day:'numeric'})}}</p>
                    <p>Weekly Copy Limit : 20 </p>
                    <i
                      class="mdi mdi-square-edit-outline feather icon-edit m-t-10 f-16"
                    ></i>
                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
 Deactivate
</button>
                  </div>
                </div> -->
                <div class="col-sm-10">
                  <form @submit.prevent="submitForm">
                    <div class="card-block">
                      <h6 class="m-b-20 p-b-5 b-b-default f-w-600">
                        Information
                      </h6>
                      <div class="row" v-if="bool">
                        <div class="col-sm-12 d-flex justify-content-end">
                          <a class="btn btn-primary" @click="toggleField()">
                            <i class="bi bi-pencil-square"></i>
                          </a>
                        </div>
                        <div class="col-sm-6">
                          <label class="m-b-2 f-w-600"> First Name: </label>
                          <p>{{ formFields.first_name }}</p>
                        </div>
                        <div class="col-sm-6">
                          <label class="m-b-2 f-w-600"> Last Name: </label>
                          <p>{{ formFields.last_name }}</p>
                        </div>
                        <div class="col-sm-6">
                          <label class="m-b-2 f-w-600"> Email: </label>
                          <p>{{ formFields.email }}</p>
                        </div>
                        <div class="col-sm-6">
                          <label class="m-b-2 f-w-600"> Phone: </label>
                          <p>{{ formFields.phone }}</p>
                        </div>
                        <div class="col-sm-6">
                          <label class="m-b-2 f-w-600"> Company Name: </label>
                          <p>{{ formFields.company }}</p>
                        </div>
                        <div class="col-sm-6">
                          <label class="m-b-2 f-w-600"> Website: </label>
                          <p>{{ formFields.website }}</p>
                        </div>
                        <div class="col-sm-6">
                          <label class="m-b-2 f-w-600"> Country: </label>
                          <p>{{ formFields.country }}</p>
                        </div>
                        <div class="col-sm-6">
                          <label class="m-b-2 f-w-600"> City: </label>
                          <p>{{ formFields.city }}</p>
                        </div>
                        <div class="col-sm-6">
                          <label class="m-b-2 f-w-600"> Address: </label>
                          <p>{{ formFields.address }}</p>
                        </div>
                      </div>

                      <div class="row" v-if="!bool">
                        <div class="col-sm-12 d-flex justify-content-end">
                          <a class="btn btn-primary" @click="toggleField()">
                            <i class="bi bi-pencil-square"></i>
                          </a>
                        </div>

                        <div class="col-sm-6">
                          <label class="m-b-10 f-w-600" for="fname">
                            First Name <span class="required">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': $v.formFields.first_name.$error,
                            }"
                            v-model="formFields.first_name"
                            :disabled="bool"
                            id="fname"
                            placeholder="Enter First Name"
                          />
                        </div>

                        <div class="col-sm-6">
                          <label class="m-b-10 f-w-600" for="lname">
                            Last Name <span class="required">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': $v.formFields.last_name.$error,
                            }"
                            id="lname"
                            :disabled="bool"
                            v-model="formFields.last_name"
                            placeholder="Enter Last Name"
                          />
                        </div>
                        <div class="col-sm-6">
                          <label class="m-b-10 f-w-600" for="email">
                            Email <span class="required">*</span>
                          </label>
                          <input
                            type="email"
                            readonly
                            class="form-control"
                            id="email"
                            v-model="formFields.email"
                            :class="{
                              'is-invalid': $v.formFields.email.$error,
                            }"
                            :disabled="bool"
                            placeholder="rntng@gmail.com"
                          />
                        </div>
                        <div class="col-sm-6">
                          <label class="m-b-10 f-w-600" for="pno">
                            Phone <span class="required">*</span>
                          </label>
                          <input
                            type="text"
                            :disabled="bool"
                            :class="{
                              'is-invalid': $v.formFields.phone.$error,
                            }"
                            v-model="formFields.phone"
                            class="form-control"
                            id="pno"
                            placeholder="Enter phone"
                          />
                        </div>
                        <div class="col-sm-6">
                          <label class="m-b-10 f-w-600" for="cmp_name">
                            Company Name <span class="required">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': $v.formFields.company.$error,
                            }"
                            id="cmp_name"
                            :disabled="bool"
                            v-model="formFields.company"
                            placeholder="Enter Company Name"
                          />
                        </div>
                        <div class="col-sm-6">
                          <label class="m-b-10 f-w-600" for="cmp_website">
                            Website
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid':
                                parseUrl == false ||
                                $v.formFields.website.$error,
                            }"
                            id="cmp_website"
                            :disabled="bool"
                            v-model="formFields.website"
                            placeholder="Enter website"
                          />
                          <span
                            class="text-danger"
                            style="font-size: 11px"
                            v-if="parseUrl == false"
                            >Enter a valid url</span
                          >
                        </div>
                        <div class="col-sm-6">
                          <label class="m-b-10 f-w-600" for="country">
                            Country <span class="required">*</span>
                          </label>
                          <v-select
                            :disabled="bool"
                            @input="setSelected"
                            label="name"
                            id="country"
                            v-model="formFields.country"
                            placeholder="select country"
                            :options="options"
                            :class="{
                              'is-invalid':
                                parseUrl == false ||
                                $v.formFields.country.$error,
                            }"
                          ></v-select>
                        </div>
                        <div class="col-sm-6">
                          <label class="m-b-10 f-w-600" for="city">
                            City <span class="required">*</span>
                          </label>
                          <input
                            type="text"
                            :disabled="bool"
                            class="form-control"
                            id="city"
                            v-model="formFields.city"
                            :class="{
                              'is-invalid':
                                parseUrl == false || $v.formFields.city.$error,
                            }"
                            placeholder="Enter City"
                          />
                        </div>
                        <div class="col-sm-6">
                          <label class="m-b-10 f-w-600" for="address">
                            Address <span class="required">*</span>
                          </label>
                          <input
                            type="text"
                            :disabled="bool"
                            class="form-control"
                            id="address"
                            v-model="formFields.address"
                            placeholder="Enter Address"
                          />
                        </div>
                        <div class="col-sm-6 password-container">
                          <label class="m-b-10 f-w-600" for="password"
                            >Password</label
                          >
                          <input
                            :disabled="bool"
                            :type="isPasswordVisible ? 'text' : 'password'"      
                            class="form-control"
                            id="password"
                            v-model="fields.password"
                            placeholder="Enter Password"
                            autocomplete="off"
                          />
                          <span id="togglePassword" class="eye-icon" @click="togglePasswordVisibility"> 
                            <i :class="isPasswordVisible ? 'bi bi-eye-fill' : 'bi bi-eye-slash-fill'" v-tooltip="isPasswordVisible ? 'hide' : 'show'"></i>
                          </span>
                        </div>
                        <div class="col-sm-6 password-container">
                          <label class="m-b-10 f-w-600" for="cpassword"
                            >Confirm Password</label
                          >
                          <input
                            :disabled="bool"
                            :type="isCPasswordVisible ? 'text' : 'password'"
                            class="form-control"
                            id="cpassword"
                            v-model="fields.confirmPassword"
                            placeholder="Confirm Password"
                            autocomplete="off"
                          />
                          <span
                            v-if="$v.fields.confirmPassword.$error"
                            style="color: red"
                            >Must be same as password</span
                          >
                          <span id="togglePassword" class="confirm-eye-icon" @click="toggleCPasswordVisibility"> 
                              <i :class="isCPasswordVisible ? 'bi bi-eye-fill' : 'bi bi-eye-slash-fill'" v-tooltip="isCPasswordVisible ? 'hide' : 'show'"></i> <!-- Change icon based on visibility -->
                          </span>
                        </div>
                        <p class="text-danger" style="font-size: 11px">
                          Leave password field empty, if you don't want to
                          change
                        </p>
                        <div class="col-sm-4 mt-3 d-flex" v-if="!bool">
                          <button
                            class="btn btn-success d-flex me-2"
                            type="submit"
                            :class="loading ? 'disabled' : ''"
                            @click="submitForm"
                          >
                            <span>Save </span>
                            <span
                              v-if="loading"
                              class="spinner-border spinner-border-sm m-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </button>
                          <button class="btn btn-secondary" @click="cancel()">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Deactivate Subscription
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <!-- <div class="col-sm-12">
                          <label class="m-b-10 f-w-600">
                            Email <span class="required">*</span>
                          </p>
                          <input type="text" readonly  class="form-control" id="exampleFormControlInput1" v-model="dForm.email" placeholder="Enter reason">
                        </div> -->
            <div class="col-sm-12">
              <label class="m-b-10 f-w-600" for="reason">
                Reason <span class="required">*</span>
              </label>
              <textarea
                type="text"
                style="height: 115px"
                class="form-control"
                id="reason"
                v-model="dForm.reason"
                placeholder="Enter reason"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="button" class="btn btn-primary">Send</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";
export default {
  name: "AdminProfile",
  components: {
    vSelect,
  },
  data() {
    return {
      options: [
        { name: "Canada", code: "CA" },
        { name: "Australia", code: "AU" },
        { name: "United States", code: "US" },
        { name: "United Kingdom", code: "UK" },
        { name: "Other", code: "other" },
      ],
      parseUrl: true,
      bool: true,
      subData: {},
      load: false,
      planData: {},
      loading: false,
      isPasswordVisible: false,
      isCPasswordVisible: false,
      dForm: {
        reason: "",
        email: "",
      },
      formFields: {
        first_name: "",
        last_name: "",
        email: "rahul@gmail.com",
        phone: "",
        company: "",
        website: "",
        address: "",
        country: "",
        city: "",
      },
      fields: {
        password: "",
        confirmPassword: "",
      },
    };
  },
  computed: { ...mapGetters(["userList"]) },
  created() {
    this.load = true;
    let id = localStorage.getItem("id");
    let st = localStorage.getItem("pay_status");
    if (st == 0) {
      this.$router.push("/payments/due");
    }
    this.fetchUser(id)
      .then(() => {
        this.formFields = this.userList?.user_data;
        this.subData = this.userList?.subscription_data;
        this.planData = this.userList?.subscription_data?.plan_data;
        this.bool = true;
        this.loading = false;
        this.dForm.email = this.userList?.user_data?.email;
        this.load = false;
      })
      .catch(() => {
        this.load = false;
      });
  },
  validations: {
    formFields: {
      first_name: { required },
      last_name: { required },
      email: { required, email },
      phone: { required },
      company: { required },
      website: "",
      address: "",
      country: { required },
      city: { required },
    },
    fields: {
      password: { minLength: 6 },
      confirmPassword: {
        minLength: 6,
        sameAsPassword: sameAs(function () {
          return this.fields.password;
        }),
      },
    },
  },

  methods: {
    ...mapActions(["fetchUser", "updateUser"]),
    setSelected(value) {
      this.formFields.country = value.name;
    },
    parseLink(href) {
      var res = href.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
      return res !== null;
    },
    editUser() {
      if (this.formFields.website != "") {
        this.parseUrl = this.parseLink(this.formFields.website);

        if (this.parseUrl == false) {
          return false;
        }
      }
      this.loading = true;
      var temp = {
        first_name: this.formFields.first_name,
        last_name: this.formFields.last_name,
        email: this.formFields.email,
        company: this.formFields.company,
        phone: this.formFields.phone,
        country: this.formFields.country,
        city: this.formFields.city,
        address: this.formFields.address,
        confirmPassword: this.fields.confirmPassword,
        password: this.fields.password,
        website: this.formFields.website,
      };
      this.updateUser(temp)
        .then(() => {
          this.formFields = this.userList?.user_data;
          this.subData = this.userList?.subscription_data;
          this.planData = this.userList?.subscription_data?.plan_data;
          this.bool = true;
          this.loading = false;
          this.dForm.email = this.userList?.user_data?.email;
          this.$toasted.show(`Profile updated`, {
            theme: "bubble",
            type: "success",
            position: "top-center",
            duration: 4000,
            singleton: true,
          });
        })
        .catch(() => {
          this.loading = false;
          this.$toasted.show(`Something went wrong,plz try again later`, {
            theme: "bubble",
            type: "Error",
            position: "top-center",
            duration: 1500,
            singleton: true,
          });
        });
    },
    submitForm(e) {
      e.preventDefault();

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.editUser();
        //alert("form submitted Successfully");
      } else {
        this.loading = false;
      }
    },
    toggleField() {
      let id = localStorage.getItem("id");
      this.bool = !this.bool;
      this.fetchUser(id).then(() => {
        this.formFields = this.userList.user_data;
        this.subData = this.userList?.subscription_data;
        this.planData = this.userList?.subscription_data?.plan_data;
        this.dForm.email = this.userList?.user_data?.emailil;
      });
    },
    cancel() {
      this.bool = true;
      this.fetchUser(id).then(() => {
        this.formFields = this.userList.user_data;
        this.subData = this.userList?.subscription_data;
        this.dForm.email = this.userList?.user_data?.email;
        this.planData = this.userList?.subscription_data?.plan_data;
      });
    },
    togglePasswordVisibility() {
            this.isPasswordVisible = !this.isPasswordVisible; // Toggle visibility
    },
    toggleCPasswordVisibility() {
        this.isCPasswordVisible = !this.isCPasswordVisible; // Toggle visibility
    },
  },
};
</script>

<style>
body {
  background-color: #f9f9fa;
}

.padding {
  padding: 3rem !important;
}

.user-card-full {
  overflow: hidden;
}

.card {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
  margin-bottom: 30px;
}

.m-r-0 {
  margin-right: 0px;
}

.m-l-0 {
  margin-left: 0px;
}

.user-card-full .user-profile {
  border-radius: 5px 0 0 5px;
}

.bg-c-lite-green {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f29263),
    to(#ee5a6f)
  );
  background: linear-gradient(to right, #ee5a6f, #f29263);
}

.user-profile {
  padding: 20px 0;
}

.card-block {
  padding: 1.25rem;
}

.m-b-25 {
  margin-bottom: 25px;
}

.img-radius {
  border-radius: 5px;
}

h6 {
  font-size: 14px;
}

.card .card-block p {
  line-height: 25px;
}

@media only screen and (min-width: 1400px) {
  p {
    font-size: 14px;
  }
}

.card-block {
  padding: 1.25rem;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.m-b-20 {
  margin-bottom: 20px;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.card .card-block p {
  line-height: 25px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.text-muted {
  color: #919aa3 !important;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.f-w-600 {
  font-weight: 600;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-t-40 {
  margin-top: 20px;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-t-40 {
  margin-top: 20px;
}

.user-card-full .social-link li {
  display: inline-block;
}

.user-card-full .social-link li a {
  font-size: 20px;
  margin: 0 10px 0 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.required {
  color: red;
}
.password-container {
    position: relative;
    display: inline-block;
}
.password-container input {
    padding-right: 30px; /* Add space for the eye icon */
}
.eye-icon {
    position: absolute;
    right: 15px; /* Position the icon to the right */
    top: 70%;
    transform: translateY(-50%); /* Center vertically */
    cursor: pointer; /* Change cursor to pointer on hover */
}
.confirm-eye-icon {
    position: absolute;
    right: 15px; 
    top: 70%;
    transform: translateY(-50%); 
    cursor: pointer; 
}
</style>
