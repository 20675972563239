import {getRequest, postRequest, downloadRequest} from '@/helper'

let settingsModule = {
    state:{
        settings: {}
    },
    getters:{
        settings: (state) => {
            return state.settings
        }
    },
    actions:{
        getSetting: async ({ commit }, key) => {
            let resp = await getRequest(`settings/${key}/get`)
            if(resp && resp.status)
            {
                commit('setSettings', resp.data)
                return resp.data
            }
            else
            {
                console.error(`key not found ${resp.message}`)
            }
        },
        downloadLog: async ({ commit }) => {
            let random  = Math.random();
            let resp    = await downloadRequest(`settings/download/log-file?q=${random}`)
            let url     = window.URL.createObjectURL(new Blob([resp]));
            let link    = document.createElement('a');
            link.href   = url;
            link.setAttribute('download', 'log.txt');
            document.body.appendChild(link);
            link.click();
            link.remove();
            return resp
        },
        updateSetting: async({commit}, data) => {
            let resp = await postRequest(`settings/update`, data)
            return resp;
        }
    },
    mutations:{
        setSettings: (state, business) => {
            return state.business = business
        }
    }
}

export default settingsModule