<template>
    <div>
        <div class="page-content page-container" id="page-content">
            <div class="padding">
                <div class="row">
                    <div class="col-xl-12 col-md-12">
                        <div class="d-flex justify-content-between">
                            <h4>Create Widget</h4>
                            <div>
                                <router-link to="/widgets" class="btn btn-sm btn-primary" >
                                    <i class="bi bi-caret-left-fill"></i>
                                    Back
                                </router-link>
                                <button v-if="formFields.config.tags.length > 0" type="button" class="ms-2 btn btn-sm btn-primary" @click="handelPreviewWidget">Preview</button>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between">
                            <ul
                                class="nav nav-tabs d-none"
                                id="myTab"
                                role="tablist"
                            >
                                <li class="nav-item" role="presentation">
                                    <button
                                        type="button"
                                        class="nav-link active"
                                        role="tab"
                                        id="layout-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#layout"
                                        aria-controls="layout"
                                        aria-selected="true"
                                    >
                                        Layout
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button
                                        type="button"
                                        class="nav-link"
                                        role="tab"
                                        id="reviews-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#reviews"
                                        aria-controls="reviews"
                                        aria-selected="true"
                                    >
                                        Reviews
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button
                                        role="tab"
                                        type="button"
                                        class="nav-link"
                                        id="settings-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#settings"
                                        aria-controls="settings"
                                        aria-selected="true"
                                    >
                                        Setting
                                    </button>
                                </li>
                            </ul>
                        </div>

                        <div class="tab-content">

                            <div class="tab-pane active" id="layout" role="tabpanel" aria-labelledby="layout-tab">
                                <div class="card user-card-full">
                                    <div class="row gx-0">
                                        <div class="col-md-12">
                                            <div class="row mb-0 p-3 pb-0">
                                                <div class="col-md-6">
                                                    <label for="title" class="form-label">
                                                        Title (The Title will not display in widget )
                                                    </label>
                                                    <input
                                                        id="title"
                                                        type="text"
                                                        name="title"
                                                        class="form-control"
                                                        :class="[$v.formFields.title.$error ? 'is-invalid' : '']"
                                                        v-model="formFields.title"
                                                        placeholder="Title of Widget"
                                                    />
                                                </div>
    
                                                <div class="col-md-6">
                                                    <label for="layouts" class="form-label">
                                                        Layouts
                                                    </label>
                                                    <select id="layouts" class="form-select form-select" v-model="formFields.config.template">
                                                        <option value="list">
                                                            List
                                                        </option>
                                                        <option value="grid">
                                                            Grid
                                                        </option>
                                                        <option value="slider">
                                                            Slider
                                                        </option>
                                                        <option value="carousel">
                                                            Carousel
                                                        </option>
                                                        <option value="masonry">
                                                            Masonry
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-2">
                                                    <div class="mb-3 p-3 pb-0">
                                                        <label for="sort_field" class="form-label">
                                                            Sort Field 
                                                        </label>
                                                        <select id="sort_field" class="form-select" v-model="formFields.config.sortField">
                                                            <option value="reviews.created_at">
                                                                Date
                                                            </option>
                                                            <option value="reviews.rating">
                                                                Rating
                                                            </option>
                                                            <option value="reviews.author">
                                                                Author Name
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-md-2">
                                                    <div class="mb-3 p-3 pb-0">
                                                        <label for="sort_type" class="form-label">
                                                            Sort Type 
                                                        </label>
                                                        <select id="sort_type" class="form-select" v-model="formFields.config.sortType">
                                                            <option value="ASC">
                                                                ASC
                                                            </option>
                                                            <option value="DESC">
                                                                DESC
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <template v-if="formFields.config.template == 'slider' || formFields.config.template == 'carousel' ">

                                                    <div class="col-md-2">
                                                        <div class="mb-3 p-3 pb-0">
                                                            <label for="loop" class="form-label">
                                                                Loop 
                                                            </label>
                                                            <div class="form-check form-switch">
                                                                <input
                                                                    id="loop"
                                                                    type="checkbox"
                                                                    class="form-check-input"
                                                                    v-model="formFields.config.loopEnable"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
            
                                                    <div class="col-md-2">
                                                        <div class="mb-3 p-3 pb-0">
                                                            <label for="pagination_dots" class="form-label">
                                                                Pagination Dots
                                                            </label>
                                                            <div class="form-check form-switch">
                                                                <input
                                                                    id="pagination_dots"    
                                                                    type="checkbox"
                                                                    class="form-check-input"
                                                                    v-model="formFields.config.paginationEnable"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
            
                                                    <div class="col-md-2">
                                                        <div class="mb-3 p-3 pb-0">
                                                            <label for="auto_play" class="form-label">
                                                                Auto Play
                                                            </label>
                                                            <div class="form-check form-switch">
                                                                <input
                                                                    id="auto_play"
                                                                    type="checkbox"
                                                                    class="form-check-input"
                                                                    v-model="formFields.config.autoPlay"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
            
                                                    <div class="col-md-2" v-if="formFields.config.autoPlay">
                                                        <div class="mb-3 p-3 pb-0">
                                                            <label for="auto_play_delay" class="form-label">
                                                                Auto Play Delay 
                                                            </label>
                                                            <select id="auto_play_delay" class="form-select" v-model="formFields.config.autoPlayDelay">
                                                                <option value="1000">
                                                                    1 Sec.
                                                                </option>
                                                                <option value="2000">
                                                                    2 Sec.
                                                                </option>
                                                                <option value="3000">
                                                                    3 Sec.
                                                                </option>
                                                                <option value="4000">
                                                                    4 Sec.
                                                                </option>
                                                                <option value="5000">
                                                                    5 Sec.
                                                                </option>
                                                                <option value="10000">
                                                                    10 Sec.
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    
                                                </template>

                                                <template v-if="formFields.config.template && ['list','grid','masonry'].includes(formFields.config.template)">
                                                    <div class="col-md-2">
                                                        <div class="mb-3 p-3 pb-0">
                                                            <label for="per_page" class="form-label">
                                                                View in one load 
                                                            </label>
                                                            <select id="per_page" v-model="formFields.config.perPage" class="form-select" >
                                                                <option value="6">6</option>
                                                                <option value="8">8</option>
                                                                <option value="10">10</option>
                                                                <option value="15">15</option>
                                                                <option value="20">20</option>
                                                                <option value="50">50</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <div class="mb-3 p-3 pb-0">
                                                            <label for="load_more_option" class="form-label">
                                                                Load More Option 
                                                            </label>
                                                            <div class="form-check form-switch">
                                                                <input
                                                                    id="load_more_option"
                                                                    type="checkbox"
                                                                    class="form-check-input"
                                                                    v-model="formFields.config.loadMore"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>

                                            </div>

                                            <div class="row" label="colors">
                                                <div class="col-md-2">
                                                    <div class="mb-3 p-3 pb-0">
                                                        <label for="card_text_color" class="form-label">
                                                            Card Text Color
                                                        </label>
                                                        <input
                                                            id="card_text_color"
                                                            type="color"
                                                            class="form-control"
                                                            v-model="formFields.config.card_text_color"
                                                        />                                              
                                                    </div>
                                                </div>
                                                <div class="col-md-2">
                                                    <div class="mb-3 p-3 pb-0">
                                                        <label for="card_background_color" class="form-label">
                                                            Card Background color 
                                                        </label>
                                                        <input
                                                            id="card_background_color"
                                                            type="color"
                                                            class="form-control"
                                                            v-model="formFields.config.card_background_color"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-2">
                                                    <div class="mb-3 p-3 pb-0">
                                                        <label for="card_background_hover_color" class="form-label">
                                                            Card Background Hover color 
                                                        </label>
                                                        <input
                                                            id="card_background_hover_color"
                                                            type="color"
                                                            class="form-control"
                                                            v-model="formFields.config.card_background_hover_color"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-2">
                                                    <div class="mb-3 p-3 pb-0">
                                                        <label for="tabs" class="form-label">
                                                            Tabs 
                                                        </label>
                                                        <div class="form-check form-switch">
                                                            <input
                                                                id="tabs"
                                                                type="checkbox"
                                                                class="form-check-input"
                                                                v-model="formFields.config.tabsEnable"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-2">
                                                    <div class="mb-3 p-3 pb-0">
                                                        <label for="overall_rating" class="form-label">
                                                            Overall Rating  
                                                        </label>
                                                        <div class="form-check form-switch">
                                                            <input
                                                                id="overall_rating"
                                                                type="checkbox"
                                                                class="form-check-input"
                                                                v-model="formFields.config.ratingInfo"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-2">
                                                    <div class="mb-3 p-3 pb-0">
                                                        <label for="custom_css_enable" class="form-label">
                                                            Custom Design Enable (CSS)
                                                        </label>
                                                        <div class="form-check form-switch">
                                                            <input
                                                                id="custom_css_enable"
                                                                type="checkbox"
                                                                class="form-check-input"
                                                                v-model="formFields.config.customCssEnable"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6" v-if="formFields.config.customCssEnable">
                                                    <div class="mb-3 p-3 pb-0">
                                                        <label for="custom_css" class="form-label">Custom CSS</label>
                                                        <vue-monaco-editor
                                                            v-model="formFields.config.customCss"
                                                            theme="vs-dark"
                                                            id="custom_css"
                                                            language="css"
                                                            style="height: 250px;"
                                                            :options="{
                                                                automaticLayout: true,
                                                                formatOnType: true,
                                                                formatOnPaste: true
                                                            }"
                                                        />
                                                        <small>Please note, we cannot guarantee that the classes will remain unchanged in future updates.</small>
                                                    </div>
                                                    
                                                </div>
                                            </div>

                                            <div class="mb-1 p-3 pb-0">
                                                <label for="" class="form-label">
                                                    Central Tags (Select Tags To Filter the reviews)
                                                </label>
                                                <br />
                                                <template v-for="tag in tags">
                                                    <div
                                                        class="form-check form-check-inline mt-1"
                                                        v-if="
                                                            tag.tag &&
                                                            tag.tag.id &&
                                                            !tag.tag.user_id
                                                        "
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            class="form-check-input widget-tag-checkbox"
                                                            :id="`${tag.tag_id}_${tag.id}`"
                                                            :value="tag.tag_id"
                                                            @click="handelTags"
                                                            :disabled="isReviewLoading"
                                                        />
                                                        <label
                                                            class="form-check-label"
                                                            :for="`${tag.tag_id}_${tag.id}`"
                                                            >{{
                                                                tag.tag.title
                                                            }}</label
                                                        >
                                                    </div>
                                                </template>
                                            </div>

                                            <div class="mb-3 p-3 pb-0">
                                                <label for="" class="form-label">
                                                    Custom Tags (Select Tags To Filter the reviews)
                                                </label>
                                                <br />
                                                <template v-for="tag in tags">
                                                    <div
                                                        class="form-check form-check-inline mt-1"
                                                        v-if="
                                                            tag.tag &&
                                                            tag.tag.id &&
                                                            tag.tag.user_id
                                                        "
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            class="form-check-input widget-tag-checkbox"
                                                            :id="`${tag.tag_id}_${tag.id}`"
                                                            :value="tag.tag_id"
                                                            :disabled="isReviewLoading"
                                                            @click="handelTags"
                                                        />
                                                        <label
                                                            class="form-check-label"
                                                            :for="`${tag.tag_id}_${tag.id}`"
                                                            >{{
                                                                tag.tag.title
                                                            }}</label
                                                        >
                                                    </div>
                                                </template>
                                            </div>

                                            <div class="m-4 mb-2">
                                                <div class="d-flex justify-content-end">
                                                    <button @click="handelCreateWidget" class="btn btn-sm btn-primary" :disabled="formLoading">
                                                        Create Widget
                                                        <Loading v-if="formLoading"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <template v-if=" formFields.config.tags.length > 0">
                                            <div class="col-md-12">
                                                <hr />
                                            </div>
                                            <div class="col-md-12 pt-0 p-3">
                                                <label for="reviews" class="form-label" >
                                                    Reviews That Will Show in Widget According to your Selection
                                                </label>

                                                <div class="row p-3 d-flex justify-content-center review-container">
                                                    <div class="loader d-flex justify-content-center" v-if="isReviewLoading">
                                                        <span class="spinner-border text-primary spinner-border mx-1" role="status" aria-hidden="true"></span>
                                                    </div>
                                                    <!-- <div class="col-md-2 m-2 p-3 rounded border position-relative"
                                                        v-for="review in reviews"
                                                    >
                                                        <div class="d-flex justify-content-center">
                                                            <img
                                                                v-if="review.avatar"
                                                                class="img-thumbnail"
                                                                alt="avatar"
                                                                :src="review.avatar"
                                                                style=" width: 40px; height: 40px; border-radius: 50% !important;"
                                                            />
                                                            <img
                                                                v-else
                                                                class="img-thumbnail"
                                                                alt="avatar"
                                                                src="../../../assets/user2.png"
                                                                style=" width: 40px; height: 40px; border-radius: 50% !important;"
                                                            />
                                                        </div>

                                                        <div class="d-flex justify-content-center">
                                                            <h6>
                                                                {{ review.author }}
                                                            </h6>
                                                        </div>

                                                        <div class="d-flex justify-content-center">
                                                            <span v-for="n in 5" :key="n" class="star" >
                                                                {{ n <= review.rating ? '★' : '☆' }}
                                                            </span>
                                                            <img
                                                                v-if=" review.platform.toLowerCase() === 'yelp'"
                                                                src="../../../assets/yelp.png"
                                                                alt="Yelp"
                                                                style="
                                                                    width: 20px;
                                                                    margin-left: 5px;
                                                                    margin-bottom: 4px;
                                                                "
                                                            />
                                                            <img
                                                                v-else-if=" review.platform.toLowerCase() ==='google'"
                                                                src="../../../assets/google.png"
                                                                alt="Google"
                                                                style="
                                                                    width: 20px;
                                                                    margin-left: 5px;
                                                                    margin-bottom: 4px;
                                                                "
                                                            />
                                                            <img
                                                                v-else-if="review.platform.toLowerCase() === 'facebook'"
                                                                src="../../../assets/facebook.png"
                                                                alt="Facebook"
                                                                style="
                                                                    width: 20px;
                                                                    margin-left: 5px;
                                                                    margin-bottom: 4px;
                                                                "
                                                            />
                                                        </div>
                                                        <i
                                                            :data-id="review.id"
                                                            @click="removeReview"
                                                            style="right: 6px; top: 2px;"
                                                            class="bi bi-x-circle-fill position-absolute cursor-pointer"
                                                        ></i>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>

                            <div class="tab-pane" id="reviews" role="tabpanel" aria-labelledby="reviews-tab">
                                <div class="card user-card-full">
                                    <div class="row gx-0 p-4">
                                        <div class="col-md-12">
                                            <h6 class="form-h6">
                                                Select Tags to show reviews in
                                                widget
                                            </h6>

                                            <div class="mb-3">
                                                <label for="" class="form-label"
                                                    ><b>Central Tags</b></label
                                                >
                                                <br />
                                                <template v-for="tag in tags">
                                                    <div
                                                        class="form-check form-check-inline mt-1"
                                                        v-if="
                                                            tag.tag &&
                                                            tag.tag.id &&
                                                            !tag.tag.user_id
                                                        "
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            class="form-check-input"
                                                            :id="`${tag.tag_id}_${tag.id}`"
                                                            :value="tag.tag_id"
                                                            @click="handelTags"
                                                        />
                                                        <label
                                                            class="form-check-label"
                                                            :for="`${tag.tag_id}_${tag.id}`"
                                                            >{{
                                                                tag.tag.title
                                                            }}</label
                                                        >
                                                    </div>
                                                </template>
                                            </div>

                                            <div class="mb-3">
                                                <label for="" class="form-label"
                                                    ><b>Custom Tags</b></label
                                                >
                                                <br />
                                                <template v-for="tag in tags">
                                                    <div
                                                        class="form-check form-check-inline mt-1"
                                                        v-if="
                                                            tag.tag &&
                                                            tag.tag.id &&
                                                            tag.tag.user_id
                                                        "
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            class="form-check-input"
                                                            :id="`${tag.tag_id}_${tag.id}`"
                                                            :value="tag.tag_id"
                                                            @click="handelTags"
                                                        />
                                                        <label
                                                            class="form-check-label"
                                                            :for="`${tag.tag_id}_${tag.id}`"
                                                            >{{
                                                                tag.tag.title
                                                            }}</label
                                                        >
                                                    </div>
                                                </template>
                                            </div>

                                            <!--  <div class="mb-3">
                                                <label for="" class="form-label"><b>Select Specific Review</b></label>
                                                <select class="form-select form-select-sm" name="" id="">
                                                    <option selected>Select one</option>
                                                    <option v-for="review in reviews" v-if="review.author"  value="">{{ review.author }}</option>
                                                </select>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tab-pane" id="settings" role="tabpanel" aria-labelledby="settings-tab" >
                                <div class="card user-card-full">
                                    <div class="row gx-0">
                                        <p>Settings</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-xl-8 col-md-8 d-none">
                        <div class="d-flex justify-content-end">
                            <button
                                @click="handelCreateWidget"
                                class="btn btn-sm btn-primary"
                            >
                                Create Widget
                            </button>
                        </div>
                        <div
                            class="card user-card-full"
                            style="margin-top: 1%"
                        ></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="viewCode">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <i
                        class="bi bi-x-lg close-icon"
                        data-bs-dismiss="modal"
                    ></i>
                    <div class="p-2">
                        <div class="mb-2">
                            <i class="bi bi-copy"></i>
                            <label for="" class="form-label"
                                ><b
                                    >Copy this code and paste in the webpage</b
                                ></label
                            >
                            <textarea
                                class="form-control"
                                style="border-style: dashed"
                                disabled
                                rows="5"
                                >{{ scriptCode }}</textarea
                            >
                        </div>
                        <div class="d-flex justify-content-center">
                            <a
                                @click="copyToClipBoard"
                                href="javascript:;"
                                style="text-decoration: none"
                                ><i class="bi bi-clipboard2"></i> Click to copy
                                the code
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="preview">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content" style="height: 800px;">
                    <i class="bi bi-x-lg close-icon" style="right: 28px !important;" data-bs-dismiss="modal"></i>
                    <div class="p-2" style="height: 100%;">
                        <div class="d-flex justify-content-center iframe-loader" style="height: 800px; align-items :center;">
                            <span class="spinner-border spinner-border mx-1 text-primary" role="status" aria-hidden="true"></span>
                        </div>
                        <iframe id="preview_template" :src="iframeUrl" frameborder="0" height="100%" width="100%"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'
import Loading from '../reviews/loading.vue';
import { VueMonacoEditor } from '@guolao/vue-monaco-editor'
import yelpImage from "../../../assets/yelp.png"
import googleImage from "../../../assets/google.png"
import facebookImage from "../../../assets/facebook.png"
import noImage from "../../../assets/user2.png"

export default {
    name: 'WidgetAdd',
    components: {
        vSelect,
        Loading,
        VueMonacoEditor
    },
    data() {
        return {
            bool: true,
            loading: false,
            formFields: {
                title: '',
                config: {
                    template: 'list',
                    tags: [],
                    removedReviews: [],
                    autoPlay:false,
                    autoPlayDelay:5000,
                    paginationEnable:false,
                    loopEnable:true,
                    loadMore:true,
                    perPage:8,
                    sortField:'reviews.created_at',
                    sortType:'DESC',
                    card_text_color:'#000000;',
                    card_background_hover_color:'#d8e4ef',
                    card_background_color:'#e0ebf5',
                    tabsEnable:true,
                    ratingInfo:true,
                    customCssEnable:false,
                    customCss:""
                },
            },
            tags: [],
            reviews: [],
            scriptCode: '',
            isReviewLoading:false,
            iframeUrl:'',
            formLoading:false,
            reviewPerPage:4,
            reviewPage:1
        }
    },
    validations: {
        formFields: {
            title: {
                required,
            },
        },
    },
    created() {
        this.fetchTags()

        /* this.$helper.getRequest('users/reviews/get-all-reviews').then((resp) => {
            if(resp.status)
            {
                this.reviews = resp.data
            }
            console.log(resp)
        }) */

        $('body').on('click','.remove-review', this.removeReview)
    },
    methods: {
        ...mapActions(['getUserReviewsWithTags', 'getSetting','previewUserWidget','addUserWidget']),
        handelCreateWidget: function () {
            this.$v.formFields.$touch()
            if (!this.$v.formFields.$invalid)
            {
                if (this.formFields.config.tags.length > 0)
                {
                    if(this.formFields.config.perPage > 0)
                    {
                        this.formLoading = true
                        this.addUserWidget(this.formFields).then((resp) => {
                            if (resp.status)
                            {
                                this.notify(resp.message)
                                this.resetForm()
                                $('body').find('#viewCode').modal('show')
                                this.setScript(resp.id)
                            }
                            else
                            {
                                this.notify(resp ? resp.message : 'Something went wrong please try again', 'danger')
                            }
                            this.formLoading = false
                        })
                    }
                    else
                    {
                        this.notify("Please Add 'View in one load more' in postive number ", 'danger')
                    } 
                } 
                else
                {
                    this.notify('Please Select atlest one tag', 'danger')
                }
            }
        },
        handelPreviewWidget: function () {
            this.$v.formFields.$touch()
            if (!this.$v.formFields.$invalid)
            {
                if (this.formFields.config.tags.length > 0)
                {
                    if(this.formFields.config.perPage > 0)
                    {
                        $('iframe#preview_template').addClass('d-none')
                        $('.iframe-loader').removeClass('d-none')
                        $('iframe#preview_template').on('load',function(){
                            $('iframe#preview_template').removeClass('d-none')
                            $('.iframe-loader').addClass('d-none')
                        })
                        
                        this.iframeUrl = ''

                        this.previewUserWidget(this.formFields).then((resp) => {
                            if (resp.status)
                            {
                                this.iframeUrl = resp.url
                                $('body').find('#preview').modal('show')
                            }
                            else
                            {
                                this.notify( resp ? resp.message : 'Something went wrong please try again','danger')
                            }
                        })
                    }
                    else
                    {
                        this.notify("Please Add 'View in one load more' in postive number ", 'danger')
                    }
                }
                else
                {
                    this.notify('Please Select atlest one tag', 'danger')
                }
            }
        },
        setScript: function (uniqueId) {
            this.getSetting('widget_script_code_template').then((resp) => {
                if (resp.widget_script_code_template) {
                    this.scriptCode = resp.widget_script_code_template.replace(
                        '{widget_unique_id}',
                        uniqueId
                    )
                }
            })
        },
        resetForm: function () {
            this.$v.formFields.$reset()
            this.formFields = {
                title: '',
                config: {
                    template: 'list',
                    tags: [],
                    removedReviews: [],
                    autoPlay:false,
                    autoPlayDelay:1000,
                    paginationEnable:false,
                    loopEnable:true,
                    loadMore:true,
                    perPage:8,
                    sortField:'reviews.created_at',
                    sortType:'DESC',
                    card_text_color:'#000000;',
                    card_background_hover_color:'#d8e4ef',
                    card_background_color:'#e0ebf5',
                    tabsEnable:true,
                    ratingInfo:true,
                    customCssEnable:false,
                    customCss:""
                }
            }
            this.reviews = []
            $('body').find('input.widget-tag-checkbox').prop('checked', false)
        },
        handelTags: function (e) {
            this.reviews = []
            this.formFields.config.removedReviews = [];
            let { checked, value } = e.target
            let tags = this.formFields.config.tags
            if (checked) {
                tags.push(value)
            } else {
                let index = tags.indexOf(value)
                if (index >= 0) {
                    tags.splice(index, 1)
                }
            }
            this.onTagSelect()
        },
        notify: function (msg, type = 'success') {
            this.$toasted.show(msg, {
                theme: 'bubble',
                type: type,
                position: 'top-center',
                duration: 2500,
                singleton: true,
            })
        },
        onTagSelect: function () {
            if (this.formFields.config.tags.length > 0) {
                this.isReviewLoading = true
                this.getUserReviewsWithTags(this.formFields.config.tags).then(
                    (resp) => {
                        this.reviews = []
                        if (resp.status) 
                        {
                            this.reviews = resp.data
                            this.reviewPage = 1;
                            this.appendReviews(true)
                        }
                        this.isReviewLoading = false
                    }
                )
            }
        },
        removeReview: function (e) {
            let id = e.target.getAttribute('data-id')
            let parentElement = e.target.parentElement
            this.formFields.config.removedReviews.push(id)
            parentElement.remove()
        },
        fetchTags: function () {
            this.$helper
                .getRequest('users/tags-reviews-relation/users-selected-tags')
                .then((resp) => {
                    if (resp.status) {
                        this.tags = resp.data
                    }
                })
        },
        copyToClipBoard: function () {
            navigator.clipboard.writeText(this.scriptCode)
            this.notify('Code copied to clipboard ')
        },
        reviewHtml:function(review){
            let platform = this.getPlatform(review.platform ? review.platform.toLowerCase() : '')
            let rating = this.getRating(review.rating)
            let avatar = review.avatar ? review.avatar : noImage;
            let html = `
                <div class="col-md-2 m-2 p-3 rounded border position-relative">
                    <div class="d-flex justify-content-center">
                        <img
                            class="img-thumbnail"
                            alt="avatar"
                            src="${avatar}"
                            style=" width: 40px; height: 40px; border-radius: 50% !important;"
                            />
                    </div>
                    <div class="d-flex justify-content-center">
                        <h6>
                            ${review.author}
                        </h6>
                    </div>
                    <div class="d-flex justify-content-center">
                        ${rating}
                        ${platform}
                    </div>
                    <i data-id="${review.id}" class="bi bi-x-circle-fill position-absolute cursor-pointer remove-review" style="right: 6px; top: 2px;">
                    </i>
                </div>`
            return html
        },
        getPlatform:function(platform){
        
        let platforms = {
            'yelp':`<img src="${yelpImage}" alt="Yelp" style="width: 20px; margin-left: 5px; margin-bottom: 4px;" />`,
            'google':`<img src="${googleImage}" alt="Yelp" style="width: 20px; margin-left: 5px; margin-bottom: 4px;" />`,
            'facebook':`<img src="${facebookImage}" alt="Yelp" style="width: 20px; margin-left: 5px; margin-bottom: 4px;" />`,
        }

            return platforms[platform]
        },
        getRating:function(rating){
            let ratings = {
                '1':`
                    <span class="star">★</span>
                    <span class="star">☆</span>
                    <span class="star">☆</span>
                    <span class="star">☆</span>
                    <span class="star">☆</span>
                    `,
                '2':`
                    <span class="star">★</span>
                    <span class="star">★</span>
                    <span class="star">☆</span>
                    <span class="star">☆</span>
                    <span class="star">☆</span>
                    `,
                '3':`
                    <span class="star">★</span>
                    <span class="star">★</span>
                    <span class="star">★</span>
                    <span class="star">☆</span>
                    <span class="star">☆</span>
                    `,
                '4':`
                    <span class="star">★</span>
                    <span class="star">★</span>
                    <span class="star">★</span>
                    <span class="star">★</span>
                    <span class="star">☆</span>
                    `,
                '5':`
                    <span class="star">★</span>
                    <span class="star">★</span>
                    <span class="star">★</span>
                    <span class="star">★</span>
                    <span class="star">★</span>
                    `,
            }

            return ratings[rating]
        },
        appendReviews:function(replace = false){
            if(document.querySelector('.review-container'))
            {
                let html = '';
                let from = (this.reviewPage-1)*this.reviewPerPage;
                let to   = this.reviewPage*this.reviewPerPage
                let reviews = this.reviews.slice(from, to)
                reviews.map((item, index) => {
                    html = `${html} ${this.reviewHtml(item)} `
                })
    
                if(replace)
                {
                    document.querySelector('.review-container').innerHTML = html
                }
                else
                {
                    document.querySelector('.review-container').insertAdjacentHTML('beforeend', html)
                }
                
                if(this.reviews.length > to)
                {
                    this.reviewPage++
                    let temp = this
                    setTimeout(function(){
                        temp.appendReviews()
                    },100)
                }
            }
        }
    },
}
</script>

<style>
.active-check {
    display: none;
}

.layout-style.active {
    border: 2px solid #0d6efd !important;
    position: relative;
}

.layout-style.active .active-check {
    display: block !important;
    position: absolute;
    top: -13px;
    right: -9px;
    color: #0d6efd;
}

body {
    background-color: #f9f9fa;
}

.padding {
    padding: 3rem !important;
}

.user-card-full {
    overflow: visible !important;
}

.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    border: none;
    margin-bottom: 30px;
}

.m-r-0 {
    margin-right: 0px;
}

.m-l-0 {
    margin-left: 0px;
}

@media (min-width: 992px) {
    .user-card-full .user-profile-div {
        border-radius: 5px 0 0 5px;
    }
}

.bg-c-lite-green {
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(#f29263),
        to(#ee5a6f)
    );
    background: linear-gradient(to right, #ee5a6f, #f29263);
}

.user-profile {
    padding: 20px 0;
}

.card-block {
    padding: 1.25rem;
}

.m-b-25 {
    margin-bottom: 25px;
}

.img-radius {
    border-radius: 5px;
}

h6 {
    font-size: 14px;
}

.card .card-block p {
    line-height: 25px;
}

@media only screen and (min-width: 1400px) {
    p {
        font-size: 14px;
    }
}

.card-block {
    padding: 1.25rem;
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0;
}

.m-b-20 {
    margin-bottom: 20px;
}

.p-b-5 {
    padding-bottom: 5px !important;
}

.card .card-block p {
    line-height: 25px;
}

.text-muted {
    color: #919aa3 !important;
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0;
}

.f-w-600 {
    font-weight: 600;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-t-40 {
    margin-top: 20px;
}

.p-b-5 {
    padding-bottom: 5px !important;
}

.m-t-40 {
    margin-top: 20px;
}

.user-card-full .social-link li {
    display: inline-block;
}

.user-card-full .social-link li a {
    font-size: 20px;
    margin: 0 10px 0 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.required {
    color: red;
}
.popup-logo {
    max-height: 120px;
    image-rendering: -webkit-optimize-contrast;
    max-width: 100px;
    height: auto;
    width: auto;
    top: 6px;
    border: solid;
}
.selected-logo {
    object-fit: contain;
    max-width: 100% !important;
    max-height: 149px;
    min-width: 149px;
    min-height: 149px;
}

.star {
    color: gold;
    font-size: 16px;
}
</style>
