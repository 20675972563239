<template>
    <div class="hello m-5">
        <div class="text-end">
            <router-link
                style="text-decoration: none"
                class="text-white btn btn-primary m-2 flex-start-end"
                aria-current="page"
                to="/admin/add/user"
                >Add User</router-link
            >
            <a
                :class="[reviewFetchStatus ? 'pointer-disabled btn-info' : 'pointer-cursor btn-primary']"
                v-tooltip="reviewFetchStatus ? 'Sync Already in Process' : 'Sync Reviews'" 
                @click="() => {
                    if(!reviewFetchStatus)
                    {
                        fetchReviewsStart('all')
                    }
                } "
                style="text-decoration: none"
                class="text-white btn m-2 flex-start-end"
                aria-current="page"
            >
                Sync Reviews
            </a>
        </div>
        <vue-good-table
            :columns="columns"
            :rows="usersList"
            :fixed-header="true"
            :sort-options="{
                enabled: true,
                initialSortBy: { field: 'id', type: 'desc' },
            }"
            :search-options="{ enabled: true, placeholder: 'Search ' }"
            :pagination-options="{
                enabled: true,
                dropdownAllowAll: false,
                perPageDropdown: [10, 20, 30, 40, 50],
                perPage: 10,
                mode: 'pages',
            }"
        >
            <template slot="table-row" slot-scope="props">
                {{
                    props.column.field != 'account_status' &&
                    props.column.field != 'plan_name' &&
                    props.column.field != 'email' &&
                    props.column.field != 'fetch_reviews_enable' &&
					props.column.field != 'businesses' &&  props.column.field != 'is_onboarding'
                        ? props.formattedRow[props.column.field]
                        : ''
                }}
				<br v-if="props.column.field == 'first_name'"/>
				
				{{
					props.column.field == 'first_name' ? props.formattedRow.email : ''
				}}
				<span v-if="props.column.field == 'businesses'">
					<template v-if="props.formattedRow.businesses && props.formattedRow.businesses.length > 0">
                        <div class="d-flex justify-content-between">
                            {{ props.formattedRow.businesses && props.formattedRow.businesses.length > 0 ? props.formattedRow.businesses[0].name : '' }}
                            <div class="d-flex justify-content-end" style="min-width:63px;">
                                <i 
                                    v-tooltip="'View Business'"
                                    data-bs-toggle="modal"
                                    data-bs-target="#viewBusiness"
                                    class="openModalBusiness m-1 bi bi-eye-fill"
                                    @click="() => { fetchBusiness(props.formattedRow.businesses[0].id) }"
                                >
                                </i>
                                <i 
                                    v-tooltip="'Update Business'"
                                    data-bs-toggle="modal"
                                    data-bs-target="#editBusiness"
                                    class="openModalBusiness m-1 bi bi-pencil-fill"
                                    @click="() => { openBusinessEditForm(props.formattedRow.businesses[0].id, props.index) }"
                                    v-if="props.formattedRow.businesses[0].verified == 1 || props.formattedRow.businesses[0].verified == 2"
                                >
                                </i>
                                <i 
                                    v-tooltip="'Delete Business'"
                                    class="openDeleteBusinessModal m-1 bi bi-trash3-fill"
                                    :data-business-id="props.formattedRow.businesses[0].id"
                                    :data-index="props.index"
                                    v-if="props.formattedRow.businesses[0].verified == 1 || props.formattedRow.businesses[0].verified == 2"
                                >
                                </i>
                            </div>
                        </div>
                        <!-- <hr class="m-1" /> -->
                        <span v-if="props.formattedRow.businesses[0].verified == 0" class="badge bg-info" >Verifying...</span>
                        <span v-if="props.formattedRow.businesses[0].verified == 1" class="badge bg-success">Verified</span>
                        <span v-tooltip="'This business not exist'" v-if="props.formattedRow.businesses[0].verified == 2" class="badge bg-danger">Unverified</span>
                    </template>
                    <button 
                        v-else
                        class="btn btn-sm btn-primary openAddBusinessModal"
                        :data-user-id="props.formattedRow.id"
                        :data-index="props.index"
                    >
                        Add Business
                    </button>
				</span>
                
				<span v-if="props.column.field == 'plan_name'">
                    <span
                        v-if="
                            props.formattedRow.plan_name == 'Admin Subscriber'
                        "
                        class="badge bg-info"
                    >
                        {{ props.formattedRow.plan_name }}
                    </span>
                    <span
                        v-else-if="props.formattedRow.plan_name == 'Monthly'"
                        class="badge bg-success"
                    >
                        {{ props.formattedRow.plan_name }}
                    </span>
                    <span
                        v-else-if="props.formattedRow.plan_name == 'Cancelled'"
                        class="badge bg-danger"
                    >
                        {{ props.formattedRow.plan_name }}
                    </span>
                    <span
                        v-else-if="
                            props.formattedRow.plan_name ==
                            'Cancelation Scheduled'
                        "
                        class="badge bg-info"
                    >
                        Auto cancel on
                        {{ props.row.cancel_at | moment('MM-DD-YYYY') }}
                    </span>
                    <span
                        v-else-if="props.formattedRow.plan_name == 'Not Started'"
                        class="badge bg-secondary"
                    >
                        {{ props.formattedRow.plan_name }}
                    </span>
                    <span
                        v-else-if="props.formattedRow.plan_name == 'Trial'"
                        class="badge bg-primary"
                    >
                        {{ props.formattedRow.plan_name }}
                    </span>
					<span v-else>-</span>
                </span>
                <span v-if="props.column.field == 'account_status'">
                    <span
                        class="badge bg-success"
                        v-if="props.formattedRow.account_status == 1"
                        >Enabled</span
                    >
                    <span
                        class="badge bg-secondary"
                        v-if="props.formattedRow.account_status == 0"
                        >Disabled</span
                    >
                </span>
				<span v-if="props.column.field == 'fetch_reviews_enable'">
                    <span class="badge bg-success" v-if="props.formattedRow.fetch_reviews_enable == 1">
                        Enabled
                    </span>
                    <br v-if="props.formattedRow.fetch_reviews_enable == 1">
                    <span 
                        class="badge bg-info"
                        v-if="props.formattedRow.fetch_reviews_enable == 1 && props.formattedRow.businesses && props.formattedRow.businesses[0]"
                        :class="[reviewFetchStatus ? 'pointer-disabled' : 'pointer-cursor']"
                        v-tooltip="reviewFetchStatus ? 'Sync Already in Process' : 'Sync Reviews'" 
                        @click="() => {
                            if(!reviewFetchStatus)
                            {
                                fetchReviewsStart(props.formattedRow.id)
                            }
                        } "
                    >
                        <template v-if="!props.formattedRow.businesses[0].review_fetch_status && reviewFetchStatusId != props.formattedRow.id">
                            Fetch Reviews <i class="bi bi-arrow-clockwise"></i>
                        </template>
                        <template v-else-if="props.formattedRow.businesses[0].review_fetch_status || reviewFetchStatusId == props.formattedRow.id">
                            Fetching <i class="bi bi-arrow-clockwise"></i>
                        </template>
                    </span>
                    <span class="badge bg-secondary" v-if="props.formattedRow.fetch_reviews_enable == 0">
                        Disabled
                    </span>
                </span>
                <span v-if="props.column.field == 'is_onboarding'">
                    <span class="badge bg-success" v-if="props.formattedRow.is_onboarding == 1">
                        Yes
                    </span>                 
                    <span class="badge bg-danger" v-if="props.formattedRow.is_onboarding == 0">
                        No
                    </span>
                </span>
                <span v-if="props.column.field == 'actions'">
                    <button
                        class="btn btn-success m-1"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal1"
                        @click="edit(props.formattedRow.id)"
                    >
                        <i class="bi bi-pencil"></i>
                    </button>
                    <button
                        class="btn btn-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        @click="openModal(props.formattedRow.id)"
                    >
                        <i class="bi bi-trash-fill"></i>
                    </button>
                </span>
            </template>
        </vue-good-table>
        
		<div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            <i class="bi bi-person-x m-1"></i> Delete User
                        </h5>
                    </div>
                    <div class="modal-body">
                        <p>Are you sure, you want to delete?</p>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            data-bs-dismiss="modal"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            class="btn btn-danger"
                            @click="onRowClick()"
                            data-bs-dismiss="modal"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="modal fade"
            id="exampleModal1"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Edit User
                        </h5>
                    </div>
                    <div class="modal-body">
                        <form v-if="!modalload" @submit.prevent="submitForm">
                            <div class="card-block">
                                <div class="row">
                                    <div class="col-sm-6 pb-3">
                                        <label
                                            class="m-b-10 f-w-600"
                                            for="fname"
                                        >
                                            First Name
                                            <span class="required">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            :class="{
                                                'is-invalid':
                                                    $v.formFields.first_name
                                                        .$error,
                                            }"
                                            v-model="formFields.first_name"
                                            id="fname"
                                            placeholder="Enter First Name"
                                        />
                                    </div>

                                    <div class="col-sm-6 pb-3">
                                        <label
                                            class="m-b-10 f-w-600"
                                            for="lname"
                                        >
                                            Last Name
                                            <span class="required">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            :class="{
                                                'is-invalid':
                                                    $v.formFields.last_name
                                                        .$error,
                                            }"
                                            id="lname"
                                            v-model="formFields.last_name"
                                            placeholder="Enter Last Name"
                                        />
                                    </div>
                                    <div class="col-sm-6 pb-3">
                                        <label
                                            class="m-b-10 f-w-600"
                                            for="email"
                                        >
                                            Email
                                            <span class="required">*</span>
                                        </label>
                                        <input
                                            type="email"
                                            readonly
                                            class="form-control"
                                            id="email"
                                            v-model="formFields.email"
                                            :class="{
                                                'is-invalid':
                                                    $v.formFields.email.$error,
                                            }"
                                            placeholder="rntng@gmail.com"
                                            autocomplete="username"
                                        />
                                    </div>
                                    <div class="col-sm-6 pb-3">
                                        <label class="m-b-10 f-w-600" for="pno">
                                            Phone
                                            <span class="required">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            :class="{
                                                'is-invalid':
                                                    $v.formFields.phone.$error,
                                            }"
                                            v-model="formFields.phone"
                                            class="form-control"
                                            id="pno"
                                            placeholder="Enter phone"
                                        />
                                    </div>
                                    <div class="col-sm-6 pb-3">
                                        <label
                                            class="m-b-10 f-w-600"
                                            for="cmp_name"
                                        >
                                            Company Name
                                            <span class="required">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            :class="{
                                                'is-invalid':
                                                    $v.formFields.company
                                                        .$error,
                                            }"
                                            id="cmp_name"
                                            v-model="formFields.company"
                                            placeholder="Enter Company Name"
                                        />
                                    </div>
                                    <div class="col-sm-6 pb-3">
                                        <label
                                            class="m-b-10 f-w-600"
                                            for="cmp_website"
                                            >Website
                                            <span class="required"
                                                >*</span
                                            ></label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            :class="{
                                                'is-invalid':
                                                    parseUrl == false ||
                                                    $v.formFields.website
                                                        .$error,
                                            }"
                                            id="cmp_website"
                                            v-model="formFields.website"
                                            placeholder="Enter website"
                                        />
                                        <span
                                            class="text-danger"
                                            style="font-size: 11px"
                                            v-if="parseUrl == false"
                                            >Enter a valid url</span
                                        >
                                    </div>
                                    <div class="col-sm-12 pb-3">
                                        <label
                                            class="m-b-10 f-w-600"
                                            for="address"
                                        >
                                            Address
                                            <span class="required">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="address"
                                            v-model="formFields.address"
                                            placeholder="Enter Address"
                                        />
                                    </div>

                                    <div class="col-sm-6 pb-3">
                                        <label
                                            class="m-b-10 f-w-600"
                                            for="country"
                                        >
                                            Country
                                            <span class="required">*</span>
                                        </label>
                                        <v-select
                                            label="name"
                                            id="country"
                                            :class="{
                                                'is-invalid':
                                                    $v.formFields.country
                                                        .$error,
                                            }"
                                            v-model="formFields.country"
                                            placeholder="select country"
                                            :options="options"
                                             @input="updateTimeZones"
                                        ></v-select>
                                    </div>
                                    <div class="col-sm-6 pb-3">
                                        <label
                                            class="m-b-10 f-w-600"
                                            for="Time Zone"
                                        >
                                            Select Time Zone
                                            <span class="required">*</span>
                                        </label>
                                        <v-select
                                            label="name"                                           
                                            id="timezone"
                                            v-model="formFields.time_zone"
                                            :options="timeZones"         
                                                :class="{
                                                'is-invalid': $v.formFields.time_zone.$error,
                                            }"
                                            placeholder="Select a time zone"                                            
                                        ></v-select>
                                    </div>
                                    <div class="col-sm-6 pb-3">
                                        <label class="m-b-10 f-w-600" for="city"
                                            >City</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="city"
                                            v-model="formFields.city"
                                            :class="{
                                                'is-invalid':
                                                    $v.formFields.city.$error,
                                            }"
                                            placeholder="Enter City"
                                        />
                                    </div>
                                    <div class="col-sm-6 pb-3 password-container">
                                       <label class="m-b-10 f-w-600" for="password">Password</label>
                                            <input
                                                :type="isPasswordVisible ? 'text' : 'password'"
                                                class="form-control"
                                                id="password"
                                                v-model="fields.password"
                                                placeholder="Enter Password"
                                                autocomplete="current-password"
                                            />
                                            <span id="togglePassword" class="eye-icon" @click="togglePasswordVisibility"> 
                                                <i :class="isPasswordVisible ? 'bi bi-eye-fill' : 'bi bi-eye-slash-fill'" v-tooltip="isPasswordVisible ? 'hide' : 'show'"></i>
                                            </span>
                                    </div>                                                                 
                                    <div class="col-sm-6 mb-2 password-container">
                                        <label
                                            class="m-b-10 f-w-600"
                                            for="cpassword"
                                            >Confirm Password</label
                                        >
                                        <input
                                            :type="isCPasswordVisible ? 'text' : 'password'"
                                            class="form-control"                                            
                                            :class="{
                                                'is-invalid':
                                                    $v.fields.confirmPassword
                                                        .$error,
                                            }"
                                            id="cpassword"
                                            v-model="fields.confirmPassword"
                                            placeholder="Confirm Password"
                                            autocomplete="current-password"
                                        />
                                        <span id="togglePassword" class="confirm-eye-icon" @click="toggleCPasswordVisibility"> 
                                                <i :class="isCPasswordVisible ? 'bi bi-eye-fill' : 'bi bi-eye-slash-fill'" v-tooltip="isCPasswordVisible ? 'hide' : 'show'"></i> <!-- Change icon based on visibility -->
                                        </span>
                                        <span
                                            v-if="
                                                $v.fields.confirmPassword.$error
                                            "
                                            style="color: red"
                                            >Must be same as password</span
                                        >
                                         <p
                                        class="text-danger mt-2"
                                        style="font-size: 11px"
                                    >
                                        Leave password field empty, if you don't
                                        want to change
                                    </p> 
                                    </div>
                                    <div class="col-sm-6 mb-2">
                                        <label class="m-b-10 f-w-600" for="is_onboarding_complete">
                                            Is Onboarding Complete?
                                        </label>
                                        <select class="form-select" @change="updateOnboarding">
                                             <option value="0" :selected="formFields.is_onboarding === 0" >No</option>
                                             <option value="1" :selected="formFields.is_onboarding === 1">Yes</option>
                                        </select>
                                    </div>                                  
                                    <div class="col-sm-6 mt-2 mb-2">
                                        <label
                                            class="form-check-label m-b-10 f-w-600"
                                            for="acc_status"
                                        >
                                            Change Account Status
                                        </label>
                                        <div class="form-check form-switch">
                                            <input
                                                class="form-check-input"
                                                v-model="status"
                                                @change="onChangeEventHandler"
                                                type="checkbox"
                                                id="acc_status"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-sm-6 mt-2 mb-3">
                                        <label
                                            class="form-check-label m-b-10 f-w-600"
                                            for="send_email"
                                        >
                                            Send Email
                                        </label>
                                        <div class="d-flex flex-column gap-2">
                                            <div class="form-check form-switch">
                                                <input
                                                    class="form-check-input"
                                                    v-model="send_email"
                                                    @change="
                                                        onChangeEventHandler1
                                                    "
                                                    type="checkbox"
                                                    id="send_email"
                                                />
                                            </div>
                                            <div>
                                                <small
                                                    class="text-danger"
                                                    v-if="
                                                        subs_status &&
                                                        send_email
                                                    "
                                                >
                                                    Only Cancellation mail will
                                                    be sent.
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 mt-2 mb-2">
                                        <label
                                            class="form-check-label m-b-10 f-w-600"
                                            for="is_monthly_feed"
                                        >
                                            Enable Monthly Feed
                                        </label>
                                        <div class="form-check form-switch">
                                            <input
                                                class="form-check-input"
                                                v-model="monthly_feed"
                                                @change="onChangeEventHandler3"
                                                type="checkbox"
                                                id="is_monthly_feed"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-sm-6 mt-2 mb-2">
                                        <label
                                            class="form-check-label m-b-10 f-w-600"
                                            for="fetch_reviews_enable"
                                        >
                                        Enable Reviews 
                                        </label>
                                        <div class="form-check form-switch">
                                            <input
                                                class="form-check-input"
                                                v-model="formFields.fetch_reviews_enable"
                                                type="checkbox"
                                                id="fetch_reviews_enable"
                                            />
                                        </div>
                                    </div>                                    
                                    <div
                                        v-if="formFields.pay_status"
                                        class="col-sm-6 mt-2 mb-3"
                                    >
                                        <label
                                            class="form-check-label m-b-10 f-w-600"
                                            for="cancel_sub"
                                        >
                                            Cancel Subscription
                                        </label>
                                        <div class="d-flex flex-column gap-2">
                                            <div class="form-check form-switch">
                                                <input
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#cancellationPopup"
                                                    class="form-check-input"
                                                    @change="
                                                        onChangeEventHandler2
                                                    "
                                                    v-model="subs_status"
                                                    type="checkbox"
                                                    id="cancel_sub"
                                                />
                                            </div>
                                            <div>
                                                <small
                                                    class="text-danger"
                                                    v-if="subs_status"
                                                >
                                                    Automated mail will be sent
                                                    on cancellation
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button
                                            type="button"
                                            class="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                            @click="cancel()"
                                        >
                                            Close
                                        </button>
                                        <button
                                            class="btn btn-primary"
                                            type="submit"
                                            @click="submitForm"
                                            :class="loading ? 'disabled' : ''"
                                        >
                                            <span
                                                v-if="loading"
                                                class="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                            Save changes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div v-if="modalload">
                            <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden"
                                        >Loading...</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
		<div
            class="modal fade"
            id="cancellationPopup"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            <i class="bi bi-person-x m-1"></i> Cancel
                            Subscription
                        </h5>
                    </div>
                    <div class="modal-body">
                        <p>Are you sure, you want to Cancel Subscription?</p>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal1"
                            data-bs-dismiss="modal"
                            @click="changeSubs"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            class="btn btn-danger"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal1"
                            data-bs-dismiss="modal"
                            @click="changeSubs1"
                        >
                            ok
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="deleteBusiness" >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Delete Business
                        </h5>
                    </div>
                    <div class="modal-body">
                        <p>Are you sure, you want to Delete Business ?</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-dismiss="modal">
                            Cancel
                        </button>
                        <button type="button" class="btn btn-danger" @click="businessDelete" data-id="">
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>

		<div class="modal fade" id="viewBusiness">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
					<div class="d-flex justify-content-center mt-2">
						<h5 class="modal-title">
							Business
						</h5>
					</div>
					<i class="bi bi-x-lg close-icon" data-bs-dismiss="modal"></i>
					<hr class="mb-0">
                    <div class="modal-body p-3">
						<div class="row">
							<div class="col-md-6">
								<table class="table">
									<thead>
										<tr>
											<th>Name:</th>
											<td>{{ business && business.name ? business.name : '-' }}</td>
										</tr>
										<tr>
											<th>Country:</th>
											<td>{{ business && business.country ? business.country : '-' }}</td>
										</tr>
										<tr>
											<th>City:</th>
											<td>{{ business && business.city ? business.city : '-' }}</td>
										</tr>
										<tr>
											<th>Postal Code:</th>
											<td>{{ business && business.postal_code ? business.postal_code : '-' }}</td>
										</tr>
                                        <tr>
											<th>Verified:</th>
											<td>
												<span
													v-if="business && business.verified == 1"
													class="badge bg-success"
												>
													Verified
												</span>
												<span 
													v-else-if="business && business.verified == 2"
													class="badge bg-danger"
												>
                                                Unverified
												</span>
                                                <span 
													v-else-if="business && business.verified == 0"
													class="badge bg-info"
												>
													Verifying
												</span>
											</td>
										</tr>
									</thead>
								</table>
							</div>
							<div class="col-md-6">
								<table class="table">
									<thead>
										<tr>
											<th>Reviews Fetch From:</th>
											<td>
												<span 
													v-if="business && business.reviews_from.length > 0"
													v-for="platform in business.reviews_from"
													class="badge bg-primary m-1"
												>
													{{ platform ? platform.toUpperCase() : '-' }}
												</span>
											</td>
										</tr>
										<tr>
											<th>Status:</th>
											<td>
												<span
													v-if="business && business.status == 1"
													class="badge bg-primary"
												>
													Active
												</span>
												<span 
													v-else-if="business && business.status == 0"
													class="badge bg-danger"
												>
													In-active
												</span>
											</td>
										</tr>
                                        <tr>
											<th>Review Auto Post:</th>
											<td>
												<span
													v-if="business && business.auto_post_status == 1"
													class="badge bg-primary"
												>
													Active
												</span>
												<span 
													v-else-if="business && business.auto_post_status == 0"
													class="badge bg-danger"
												>
													In-active
												</span>
											</td>
										</tr>
										<tr>
											<th>Created at:</th>
											<td>{{ business && business.created_at ? this.$helper.getFormatedDateTime(business.created_at) : '-' }}</td>
										</tr>
										<tr>
											<th>Updated at:</th>
											<td>{{ business && business.updated_at ? this.$helper.getFormatedDateTime(business.updated_at) : '-' }}</td>
										</tr>
									</thead>
								</table>
							</div>
						</div>
                    </div>
                </div>
            </div>
        </div>

		<div class="modal fade" id="editBusiness">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
					<div class="d-flex justify-content-center mt-2">
						<h5 class="modal-title">
							Update Business
						</h5>
					</div>
					<i class="bi bi-x-lg close-icon" data-bs-dismiss="modal"></i>
					<hr class="mb-0">
					<form @submit.prevent="businessUpdate">
                    	<div class="modal-body p-3">
							<div class="row">
								<div class="col-md-6">
									<div class="mb-3">
										<label for="" class="form-label">Name</label>
										<input
                                            type="text"
                                            class="form-control"
                                            :class="[$v.businessFormFields.name.$error ? 'is-invalid' : '']"
                                            :disabled="business.verified == 2 ? false : true"
											v-model="businessFormFields.name"
										>
									</div>
									<div class="mb-3">
										<label for="" class="form-label">Postal Code</label>
										<input 
											type="text"
											class="form-control"
											v-model="businessFormFields.postal_code"
                                            :disabled="business.verified == 2 ? false : true"
                                            :class="[$v.businessFormFields.postal_code.$error ? 'is-invalid' : '']"
										>
									</div>
                                    
								</div>
								<div class="col-md-6">
									<div class="mb-3">
										<label for="" class="form-label">Country</label>
                                        <v-select
                                            id="country"
                                            v-model="businessFormFields.country"
                                            placeholder="Select Country"
                                            :options="countries"
                                            :reduce="countries => countries.value"                                             
                                            :disabled="business.verified == 2 ? false : true"
                                            :class="[$v.businessFormFields.country.$error ? 'is-invalid' : '']"
                                        ></v-select>
									</div>
									<div class="mb-3">
										<label for="" class="form-label">City</label>
										<input 
											type="text"
											class="form-control"
											v-model="businessFormFields.city"
                                            :disabled="business.verified == 2 ? false : true"
											:class="[$v.businessFormFields.city.$error ? 'is-invalid' : '']"
										>
									</div>
								</div>
                                <div class="col-md-12">
                                    <div class="mb-3">
										<label for="social_platform" class="form-label">Social Platforms</label>
                                        <v-select
                                            id="platform"
                                            v-model="businessFormFields.platform"
                                            placeholder="Select platform"
                                            :options="socialPlatform"
                                            :multiple="true"
                                            :reduce="socialPlatform => socialPlatform.value"
                                            :class="[$v.businessFormFields.platform.$error ? 'is-invalid' : '']"
                                        ></v-select>
									</div>
                                </div>
                                <div class="col-md-12">
                                    <label class="form-check-label m-b-10 f-w-600" for="auto_post_status">
                                        Review Auto Post
                                    </label>
                                    <div class="form-check form-switch">
                                        <input
                                            class="form-check-input"
                                            v-model="businessFormFields.auto_post_status"
                                            type="checkbox"
                                            id="auto_post_status"
                                        />
                                    </div>
                                </div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
							<button type="submit" class="btn btn-primary" :disabled="businessFormLoading">
                                Update {{ business.verified == 2 ? '& Verify' : '' }}
                                <Loading v-if="businessFormLoading" />
                            </button>
						</div>
					</form>
                </div>
            </div>
        </div>

        <div class="modal fade" id="addBusiness">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
					<div class="d-flex justify-content-center mt-2">
						<h5 class="modal-title">
							Add Business
						</h5>
					</div>
					<i class="bi bi-x-lg close-icon" data-bs-dismiss="modal"></i>
					<hr class="mb-0">
					<form @submit.prevent="businessAdd">
                    	<div class="modal-body p-3">
							<div class="row">
								<div class="col-md-6">
									<div class="mb-3">
										<label for="name_add_business" class="form-label">Name</label>
										<input
                                            id="name_add_business"
										 	type="text"
											class="form-control"
											:class="[$v.businessFormFields.name.$error ? 'is-invalid' : '']"
											v-model="businessFormFields.name"
										>
									</div>
									<div class="mb-3">
										<label for="postal_code_add_business" class="form-label">Postal Code</label>
										<input 
                                            id="postal_code_add_business"
											type="text"
											class="form-control"
											v-model="businessFormFields.postal_code"
											:class="[$v.businessFormFields.postal_code.$error ? 'is-invalid' : '']"
										>
									</div>
                                    
								</div>
								<div class="col-md-6">
									<div class="mb-3">
										<label for="country_add_business" class="form-label">Country</label>
                                        <v-select
                                            id="country_add_business"
                                            v-model="businessFormFields.country"
                                            placeholder="Select Country"
                                            :options="countries"
                                            :reduce="countries => countries.value"
                                            :class="[$v.businessFormFields.country.$error ? 'is-invalid' : '']"
                                        ></v-select>
									</div>
									<div class="mb-3">
										<label for="city_add_business" class="form-label">City</label>
										<input 
                                            id="city_add_business"
											type="text"
											class="form-control"
											v-model="businessFormFields.city"
											:class="[$v.businessFormFields.city.$error ? 'is-invalid' : '']"
										>
									</div>
								</div>
                                <div class="col-md-12">
                                    <div class="mb-3">
										<label for="social_platform_add_business" class="form-label">Social Platforms</label>
                                        <v-select
                                            id="social_platform_add_business"
                                            v-model="businessFormFields.platform"
                                            placeholder="Select platform"
                                            :options="socialPlatform"
                                            :multiple="true"
                                            :reduce="socialPlatform => socialPlatform.value"
                                            :class="[$v.businessFormFields.platform.$error ? 'is-invalid' : '']"
                                        ></v-select>
									</div>
                                </div>
                                <div class="col-md-12">
                                    <label class="form-check-label m-b-10 f-w-600" for="auto_post_status_add">
                                        Review Auto Post
                                    </label>
                                    <div class="form-check form-switch">
                                        <input
                                            class="form-check-input"
                                            v-model="businessFormFields.auto_post_status"
                                            type="checkbox"
                                            id="auto_post_status_add"
                                        />
                                    </div>
                                </div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
							<button type="submit" class="btn btn-primary" :disabled="businessFormLoading">
                                Save & Verify
                                <Loading v-if="businessFormLoading" />
                            </button>
						</div>
					</form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    required,
    email,
    url,
    minLength,
    sameAs,
} from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'
import { countryTimeZones } from './timezoneData';
import vSelect from 'vue-select'
import moment from 'moment-timezone'
import Loading from '../user/reviews/loading.vue';
export default {
    name: 'UserList',
    components: {
        vSelect,
        Loading
    },
    props: {
        msg: String,
    },
    data() {
        return {
            timeZones: [],
            isPasswordVisible: false,
            isCPasswordVisible: false,
            reviewFetchStatus:false,
            reviewFetchStatusId:null,
            parseUrl: true,
            modalload: false,
            subs_status: false,
            subs_status1: false,
            status: '',
            monthly_feed: false,
            send_email: false,
            formFields: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                company: '',
                website: '',
                address: '',
                country: '',
                time_zone:'',
                city:'',
                account_status: '',
                is_monthly_status: '',
                pay_status: '',
                fetch_reviews_enable: '',
            },
            fields: {
                password: '',
                confirmPassword: '',
            },
            feeds: [],
            loading: false,
            role: '',
            val: false,
            userId: '',
            columns: [
                {
                    label: 'id',
                    field: 'id',
                    sortable: true,
					width: '5%',
                },
                {
                    label: 'User',
                    field: 'first_name',
                    sortable: true,
					width: '15%',
                },
                {
                    label: 'Email',
                    field: 'email',
                    sortable: true,
					hidden: true
                },
				{
                    label: 'Business',
                    field: 'businesses',
                    sortable: true,
					width: '10%',
                },
                {
                    label: 'Fetch Reviews ',
                    field: 'fetch_reviews_enable',
                    sortable: true,
					width: '10%',
                },
                {
                    label: 'Is Onboarding Complete?',
                    field: 'is_onboarding',
                    sortable: true,
					width: '10%',
                },
				{
                    label: 'Plan Name',
                    field: 'plan_name',
                    sortable: false,
					width: '10%',
                },
                {
                    label: 'Status',
                    field: 'account_status',
                    formatFn: this.formatFn,
                    sortable: false,
					width: '10%',
                },
                {
                    label: 'Action',
                    field: 'actions',
                    sortable: false,
					width: '10%',
                    globalSearchDisabled: true,
                },

                // ...
            ],
            options: [
                { name: 'Canada', code: 'CA' },
                { name: 'Australia', code: 'AU' },
                { name: 'United States', code: 'US' },
                { name: 'United Kingdom', code: 'UK' },
                { name: 'Other', code: 'other' },
            ],
			businessFormFields:{
				id:'',
				name:'',
				country:'',
				city:'',
				postal_code:'',
                platform:'',
                auto_post_status:0,
			},
            countries:[
                { label: "Canada", value: "CAN" },
                { label: "Australia", value: "AUS" },
                { label: "United States", value: "USA" },
                { label: "United Kingdom", value: "GBR" },
                { label: "Germany", value: "DEU" },
                { label: "France", value: "FRA" },
                { label: "Japan", value: "JPN" },
                { label: "India", value: "IND" },
                { label: "Brazil", value: "BRA" },
                { label: "China", value: "CHN" },
                { label: "Mexico", value: "MEX" },
                { label: "South Africa", value: "ZAF" },
                { label: "Italy", value: "ITA" },
                { label: "Spain", value: "ESP" },
                { label: "Russia", value: "RUS" },
                { label: "Netherlands", value: "NLD" },
                { label: "Sweden", value: "SWE" },
                { label: "Norway", value: "NOR" },
                { label: "Switzerland", value: "CHE" },
                { label: "South Korea", value: "KOR" },
                { label: "Turkey", value: "TUR" },
            ],
            socialPlatform:[],
            verifingBusinessInProcess:[],
            intervalId:'',
            businessFormLoading:false
        }
    },
    validations: {
        formFields: {
            first_name: { required },
            last_name: { required },
            email: { required, email },
            phone: { required },
            company: { required },
            website: { required },
            address: '',
            account_status: '',
            is_monthly_feed: '',
            country: { required },
            time_zone: { required },
            city: { required },
            pay_status: '',
            fetch_reviews_enable: '',
            is_onboarding:''
        },
        fields: {
            password: { minLength: 6 },
            confirmPassword: {
                minLength: 6,
                sameAsPassword: sameAs(function () {
                    return this.fields.password
                }),
            },
        },
		businessFormFields:{
			name:{
				required
			},
			country:{
				required
			},
			city:{
				required
			},
			postal_code:{
				required
			},
            platform:{
                required
            }
		}
    },
    computed: { ...mapGetters(['usersList', 'userList','business']) },
    created() {
        this.fetchListings()

        this.getSetting('social_platforms').then((resp) => {
            let data = [];
            resp.social_platforms.map(function(value, index){
                
                data.push({
                    label:value.charAt(0).toUpperCase() + value.slice(1),
                    value:value,
                })
            })
            this.socialPlatform = data
        })

        $('body').on('click','button.openAddBusinessModal', function(e) {
            let that    = $(this);
            let modal   = $('body').find('#addBusiness'); 
            let btn     = modal.find('button[type=submit]') 
            let userId  = that.attr('data-user-id')
            let listingIndex  = that.attr('data-index')
            btn.attr('data-user-id', userId)
            btn.attr('data-index',listingIndex)
            modal.modal('show')
        })
        $('body').on('hide.bs.modal','div#addBusiness', this.resetBusinessForm)
        $('body').on('hide.bs.modal','div#editBusiness', this.resetBusinessForm)

        $('body').on('click','i.openDeleteBusinessModal', function(e) {
            let that    = $(this);
            let modal   = $('body').find('#deleteBusiness'); 
            let btn     = modal.find('button[data-id]') 
            let businessId  = that.attr('data-business-id')
            let listingIndex  = that.attr('data-index')
            btn.attr('data-id', businessId)
            btn.attr('data-index',listingIndex)
            modal.modal('show')
        })

        $('body').on('hide.bs.modal','div#deleteBusiness', function(e) {
            let that    = $(this);
            let btn     = that.find('button[data-id]') 
            btn.attr('data-id', '')
            btn.attr('data-index','')
        })
    },
    methods: {
        ...mapActions([
            'fetchUsers',
            'deleteUsers',
            'fetchUserById',
            'updateAccount',
            'updateUser1',
			'fetchBusiness',
			'updateBusiness',
            'addBusiness',
            'getSetting',
            'deleteBusiness'
        ]),
        fetchListings:function(){
            this.fetchUsers()
                .then((response) => {
                    if(response.status)
                    {
                        if(response?.users_data?.data?.length > 0)
                        {
                            let data = response.users_data.data;
                            data.map((item, index) => {
                                if(item?.businesses?.length > 0)
                                {
                                    if(item.businesses[0]?.verified == 0)
                                    {
                                        this.verifingBusinessInProcess.push({
                                            index:index,
                                            id:item.businesses[0].id
                                        });
                                    }
                                }
                            })
                        }

                    }
                    this.startInterval()
                    this.reviewFetchStatus = parseInt(response.fetch_status) ? true : false;
                    
                })
                .catch((err) => {
                    localStorage.clear()
                    this.$router.push('/login')
                })
            this.feeds = JSON.parse(JSON.stringify(this.usersList))
        },
        startInterval:function(){
            /* this.intervalRefreshBusinessStatus();
            return true; */
            if(this.intervalId)
            {
                clearInterval(this.intervalId)
            }
            this.intervalId = setInterval(this.intervalRefreshBusinessStatus, 5500);
        },
        intervalRefreshBusinessStatus:function(){
            if(this.verifingBusinessInProcess.length > 0)
            {
                let query = '';
                let indexes = {};
                this.verifingBusinessInProcess.map((item) => {
                    query = `ids[]=${item.id}&${query}`
                    indexes[item.id] = item.index
                })

                this.$helper.getRequest(`admin/business/fetch-business-status?${query}`).then((resp) => {
                    if(resp.status)
                    {
                        let data = resp.data

                        if(data.length > 0)
                        {
                            let index;
                            data.map((item) => {
                                index = indexes[item.id]
                                if(this.usersList[index])
                                {
                                    if(this.usersList[index]?.businesses?.length > 0)
                                    {
                                        this.usersList[index].businesses[0].verified = item.verified
                                        if(!item.verified == 0)
                                        {
                                            let temp = this.verifingBusinessInProcess;
                                            this.verifingBusinessInProcess = []
                                            temp.map((record) => {
                                                if(record.id != item.id)
                                                {
                                                    this.verifingBusinessInProcess.push(record)
                                                }
                                            })
                                        }
                                    }
                                }
                            })
                        }
                    }
                })
            }
            else
            {
                if(this.intervalId)
                {
                    clearInterval(this.intervalId)
                }
            }
        },
        updateTimeZones() {             
            this.timeZones = countryTimeZones[this.formFields.country.name];      
            this.time_zone = ""; 
        },
        updateOnboarding(event) {
          this.formFields.is_onboarding = event.target.value;
        },
        onChangeEventHandler1(event) {
            this.send_email = event.target.checked
        },
        onChangeEventHandler2(event) {
            this.subs_status1 = event.target.checked
        },
        onChangeEventHandler3(event) {
            this.is_monthly_feed = event.target.checked
        },
        onChangeEventHandler(event) {
            let bool
            if (event.target.checked) {
                bool = 1
            } else {
                bool = 0
            }
            let obj = {
                account_status: bool,
            }
            this.formFields.account_status = bool
        },
        formatFn: function (value) {
            if (value == 1) {
                return true
            } else {
                return false
            }
        },
        changeSubs() {
            this.subs_status = false
        },
        changeSubs1() {
            this.subs_status = true
        },
        edit(id) {
            this.$v.$reset()
            this.userId = id
            this.modalload = true
            this.send_email = false
            this.fetchUserById(id)
                .then((data) => {
                    this.formFields = this.userList?.user_data
                    this.timeZones = countryTimeZones[this.formFields.country];   
                    // if(this.userList?.user_data.pay_status){
                    //   this.subs_status=true
                    // }else{
                    //   this.subs_status=false
                    // }

                    if (this.userList?.user_data.account_status) {
                        this.status = true
                    } else {
                        this.status = false
                    }
                    if (this.userList?.user_data.is_monthly_feed) {
                        this.monthly_feed = true
                    } else {
                        this.monthly_feed = false
                    }
                    this.modalload = false
                })
                .catch(() => {
                    this.modalload = false
                })
        },
        onRowClick() {
            // console.log(this.userId)
            this.deleteUsers(this.userId)
        },
        openModal(id) {
            this.userId = id
        },
        onEditClick(id) {},
        parseLink(href) {
            var res = href.match(
                /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
            )
            return res !== null
        },
        editUser() {
            if (this.formFields.website != '') {
                this.parseUrl = this.parseLink(this.formFields.website)

                if (this.parseUrl == false) {
                    return false
                }
            }
            this.loading = true
            var temp = {
                first_name: this.formFields.first_name,
                last_name: this.formFields.last_name,
                email: this.formFields.email,
                company: this.formFields.company,
                phone: this.formFields.phone,
                country: this.formFields.country.name
                    ? this.formFields.country.name
                    : this.formFields.country,
                time_zone: this.formFields.time_zone,
                city: this.formFields.city,
                address: this.formFields.address,
                website: this.formFields.website,
                user_id: this.userId,
                account_status: this.formFields.account_status,
                password: this.fields.password,
                confirmPassword: this.fields.confirmPassword,
                send_email: this.send_email,
                is_monthly_feed: this.is_monthly_feed,
                cancel_subs: this.subs_status,
                fetch_reviews_enable: this.formFields.fetch_reviews_enable,
                is_onboarding: this.formFields.is_onboarding,
            }
            //  console.log("obj", temp);
            this.updateUser1(temp)
                .then(() => {
                    ;(this.subs_status = false),
                        $('#exampleModal1').modal('hide')
                    this.$toasted.show(`User Successfully updated `, {
                        theme: 'bubble',
                        type: 'success',
                        position: 'top-center',
                        duration: 1000,
                        singleton: true,
                    })
                    this.loading = false
                    this.fields.password = '';
                    this.fields.confirmPassword = '';
                })
                .catch(() => {
                    this.subs_status = false
                    this.$toasted.show(`Something went wrong`, {
                        theme: 'bubble',
                        type: 'Error',
                        position: 'top-center',
                        duration: 1000,
                        singleton: true,
                    })
                    this.loading = false
                })
        },
        submitForm(e) {
            e.preventDefault()
            this.$v.$touch()
            if (!this.$v.formFields.$invalid) {
                this.editUser()
                //alert("form submitted Successfully");
            } else {
                // alert("form submission failed");
            }
        },
        cancel() {
            this.subs_status = false
            if (this.formFields.account_status) {
                this.status = true
            } else {
                this.status = false
            }
        },
		openBusinessEditForm(id, index){
            $('body').find('div#editBusiness').find('button[type=submit]').attr('data-index', index)
			this.fetchBusiness(id).then(() => {
				this.businessFormFields = {
					id:this.business.id,
					name:this.business.name,
					country:this.business.country,
					city:this.business.city,
					postal_code:this.business.postal_code,
					auto_post_status:this.business.auto_post_status,
					platform:this.business.reviews_from,
				}
			})
		},
		async businessUpdate(){
			this.$v.businessFormFields.$touch()
			if(!this.$v.businessFormFields.$invalid)
			{
                this.businessFormLoading = true
                let temp = {}
                if(this.business.verified == 2)
                {
                    temp = {
                        id:this.businessFormFields.id,
                        name:this.businessFormFields.name,
                        country:this.businessFormFields.country,
                        city:this.businessFormFields.city,
                        postal_code:this.businessFormFields.postal_code,
                        auto_post_status:this.businessFormFields.auto_post_status,
                        reviews_from:this.businessFormFields.platform,
                    }
                }
                else
                {
                    temp = {
                        id:this.businessFormFields.id,
                        auto_post_status:this.businessFormFields.auto_post_status,
                        reviews_from:this.businessFormFields.platform,
                    }
                }
				let resp = await this.updateBusiness(temp)	
				
                if(resp && resp.status)
				{
					this.notify(resp.message)
					let closeBtn = document.querySelector('#editBusiness').querySelector('.close-icon')
					if(closeBtn)
					{
						closeBtn.click()	
					}

                    this.fetchListings();
				}
				else
				{
					this.notify(resp.message,'danger')
				}
                this.businessFormLoading = false
			}
			else
			{
				console.log('errors')
			}
		},
        async businessAdd(e){
            let submitBtn = e.target.querySelector('button[type=submit]');
            
            this.$v.businessFormFields.$touch()
			if(!this.$v.businessFormFields.$invalid)
			{
                this.businessFormLoading = true
                let temp = {
                    user_id:submitBtn.getAttribute('data-user-id'),
					city:this.businessFormFields.city,
					name:this.businessFormFields.name,
					country:this.businessFormFields.country,
					reviews_from:this.businessFormFields.platform,
					postal_code:this.businessFormFields.postal_code,
					auto_post_status:this.businessFormFields.auto_post_status,
                }
				let resp = await this.addBusiness(temp)	
				if(resp && resp.status)
				{
					this.notify(resp.message)
					$('body').find('#addBusiness').find('.close-icon').click();
                    this.usersList.map((user, index) => {
                        if(user.id == temp.user_id)
                        {
                            user.businesses.push(resp.business)
                            this.verifingBusinessInProcess.push({
                                index:index,
                                id:resp.business.id,
                            })
                        }
                    })
                    this.startInterval();
                    this.verifyBusiness(resp.business.id);
                    this.businessFormLoading = false
				}
				else
				{
					this.notify(resp.message,'danger')
				}
			}
			else
			{
				console.log('errors')
			}
		},
        async fetchReviewsStart(id){
            if(confirm("Are You Sure ?"))
            {
                let resp = await this.$helper.getRequest(`users/fetch-reviews/${id}`)

                if(resp && resp.status)
                {
                    this.reviewFetchStatus = true;
                    this.reviewFetchStatusId = id;
                    this.$toasted.show(resp.message,{
                        theme: 'bubble',
                        type: 'success',
                        position: 'top-center',
                        duration: 1000,
                        singleton: true,
                    })
                }
                else
                {
                    this.$toasted.show(resp.message,{
                        theme: 'bubble',
                        type: 'error',
                        position: 'top-center',
                        duration: 1000,
                        singleton: true,
                    })
                }
            }
        },
        verifyBusiness(id){
            return true;
            this.$helper.getRequest(`business/${id}/verify-business`).then((resp) => {
                if(resp && resp.status)
				{
					this.$toasted.show(resp.message, {
						theme: 'bubble',
                        type: 'success',
                        position: 'top-center',
                        duration: 1000,
                        singleton: true,
                    })
				}
				else
				{
					this.$toasted.show(resp.message, {
                        theme: 'bubble',
                        type: 'danger',
                        position: 'top-center',
                        duration: 1000,
                        singleton: true,
                    })
				}
            })
        },
        resetBusinessForm:function(e){

            let that = $(e.target);
            let btn  = that.find('button[type=submit]') 
            
           setTimeout(function(){
                btn.attr('data-user-id','');
                btn.attr('data-index','');
           },200)
            
            this.$v.businessFormFields.$reset()
            this.businessFormFields = {
				id:'',
				name:'',
				country:'',
				city:'',
				postal_code:'',
                platform:''
            }   
        },
        businessDelete:function(e){
            let {target} = e;
            let id = target.getAttribute('data-id');
            let index = target.getAttribute('data-index');
            if(id)
            {
                this.deleteBusiness(id).then((resp) => {
                    if(resp?.status)
                    {
                        this.notify(resp.message)
                        /* if(index)
                        {
                            this.usersList[index].businesses = [];
                        } */
                        this.fetchListings();

                        $('body').find('div#deleteBusiness').modal('hide')
                    }
                    else
                    {
                        this.notify(resp?.message ? resp.message : 'Something went wrong please try in some time','danger')
                    }
                })
            }
            else
            {
                this.notify('Something went wrong please try in some time','danger')
            }
        },
        notify:function(msg, type = 'success'){
            this.$toasted.show(msg, {
                type: type,
                theme: 'bubble',
                duration: 2500,
                singleton: true,
                position: 'top-center',
            })
        },
        togglePasswordVisibility() {
            this.isPasswordVisible = !this.isPasswordVisible; // Toggle visibility
        },
        toggleCPasswordVisibility() {
            this.isCPasswordVisible = !this.isCPasswordVisible; // Toggle visibility
        },
    },
}
</script>

<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

.form-switch .form-check-input {
    width: 50px !important;
    height: 2rem !important;
}
.close-icon {
	position: absolute;
	top: 10px;
	right: 10px;
}

.openModalBusiness, .openDeleteBusinessModal { 
	cursor: pointer;
}

.pointer-cursor {
    cursor: pointer;
}

.pointer-disabled {
    cursor: not-allowed;
}
.password-container {
    position: relative;
    display: inline-block;
}

.password-container input {
    padding-right: 30px; /* Add space for the eye icon */
}
.eye-icon {
    position: absolute;
    right: 15px; /* Position the icon to the right */
    top: 60%;
    transform: translateY(-50%); /* Center vertically */
    cursor: pointer; /* Change cursor to pointer on hover */
}
.confirm-eye-icon {
        position: absolute;
        right: 15px; 
        top: 50%;
        transform: translateY(-50%); 
        cursor: pointer; 
}
</style>