<template>
    <div>
        <div class="page-content page-container" id="page-content">
            <div class="padding container">
                <div class="row d-flex justify-content-center">
                    <div class="col-12">
                        <div class="d-flex justify-content-between">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <router-link class="nav-link" style="color: #0d6efd !important;" to="/business">
                                        Business
                                    </router-link>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active">
                                        Review Posting Setting
                                    </button>
                                </li>
                            </ul>
                            <div>
                                <router-link to="/profile" v-tooltip="'Manage Profile'" class="btn-sm btn btn-primary">
                                    Manage Profile
                                </router-link>
                            </div>
                        </div>
                        <div class="tab-content">
                            <div class="tab-pane active">
                                <div class="card user-card-full">
                                    <div class="d-flex justify-content-between mt-3 ms-3">
                                        <h6 class="mt-2 pb-2 f-w-600">Review Posting Setting</h6 >
                                        <button class="btn btn-primary btn-sm mb-3 me-3 d-none">Manage Profile</button>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <hr class="m-0">
                                        </div>
                                    </div>
                                    <div class="row m-2">
                                        <div class="col-md-12">
                                            <label class="form-check-label mb-0 f-w-600" for="review_post_status">
                                                Review Auto Post 
                                            </label>
                                            <div class="form-check form-switch">
                                                <input @change="updatePostStatus" id="review_post_status" type="checkbox" v-model="auto_post_status" class="form-check-input" />
                                            </div>
                                        </div>
                                    </div>
                                    <template v-if="auto_post_status">
                                        <div class="row m-2 mb-1 d-flex justify-content-center">
                                            <label for="" class="form-label">Templates</label>
                                            <div 
                                                class="col-md-2 mb-1 template-div"
                                                v-for="(template, index) in  templates"
                                                >
                                                <small class="selected-text" v-if="template.id == formFields.template_id"><strong>Selected</strong></small>
                                                <div
                                                    :class="template.id == formFields.template_id ? 'active' : ''" 
                                                    class="template-card">
                                                    <img 
                                                        @click="() => { formFields.template_id = template.id }"
                                                        :src="template.url" class="w-100 mb-3">
                                                </div>
                                                <a href="javascript:;" @click="() => { templatePreview(template.id) }" class="preview_link">Preview</a>
                                            </div>
                                        </div>
                                        <div class="row mt-4 ms-3">
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <label for="color" class="form-label">Background Color</label>
                                                    <input type="color" id="color" class="form-control" v-model="formFields.backgroundcolor" />
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div class="col-md-3 d-none">
                                                        <div class="mb-3">
                                                            <label for="color" class="form-label">Post Scheduled Monthly/Weekly ?</label>
                                                            <v-select 
                                                                :options="scheduleType"
                                                                placeholder="Please Select"
                                                                v-model="formFields.schedule_type"
                                                                :reduce="scheduleType => scheduleType.value"
                                                                :class="$v.formFields.schedule_type.$error ? 'is-invalid' : ''"
                                                                />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3" v-if="formFields.schedule_type == 'week'">
                                                        <div class="mb-1" >
                                                            <label for="week_day" class="form-label">Post Schedule Week Day</label>
                                                            <v-select 
                                                                :clearable="false"
                                                                id="week_day"
                                                                v-model="formFields.schedule_week_day"
                                                                :reduce="weekDays => weekDays.value"
                                                                :options="weekDays"
                                                                :class="$v.formFields.schedule_week_day.$error ? 'is-invalid' : ''"
                                                                placeholder="Please Select"
                                                                />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3" v-if="formFields.schedule_type == 'month'">
                                                        <div class="mb-1 month_day_select" >
                                                            <label for="color" class="form-label">Month Date</label>
                                                            <v-select 
                                                                v-model="formFields.schedule_month_date"
                                                                :options="monthsDates"
                                                                :class="$v.formFields.schedule_month_date.$error ? 'is-invalid' : ''"
                                                                placeholder="Please Select"
                                                                />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="mb-1 hour_select" v-if="(formFields.schedule_type == 'week' && formFields.schedule_week_day) || (formFields.schedule_type == 'month' && formFields.schedule_month_date)">
                                                            <label for="cron_hour" class="form-label">Post Schedule Hour</label>
                                                            <v-select 
                                                                :clearable="false"
                                                                id="cron_hour"
                                                                v-model="formFields.schedule_hour"
                                                                :reduce="hours => hours.value"
                                                                :options="hours"
                                                                :class="$v.formFields.schedule_hour.$error ? 'is-invalid' : ''"
                                                                placeholder="Please Select"
                                                                />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 mt-4 ms-0 pt-2" v-if="totalFeeds > 0">
                                                        <i class="bi bi-info-circle mt-4" v-tooltip="`Total Feeds To Schedule: ${totalFeeds}`"></i>
                                                    </div>
                                                    <div class="col-md-12 mb-3 mt-0 note">
                                                        <small class="text-danger">{{review_note}}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-3">
                                                <div class="d-flex justify-content-start">
                                                    <div class="d-grid gap-2">
                                                        <button type="button" :disabled="isLoading" @click="updatePostSettings" class="btn-sm btn btn-primary">
                                                            Save
                                                            <Loading v-if="isLoading" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="previewModal">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
					<div class="d-flex justify-content-center mt-2">
						<h5 class="modal-title">
							Preview
						</h5>
					</div>
					<i class="bi bi-x-lg close-icon" data-bs-dismiss="modal"></i>
					<hr class="mb-0">
                    <div class="modal-body p-3">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="d-flex justify-content-center iframe-loader" style="height: 800px; align-items :center;">
                                    <span class="spinner-border spinner-border mx-1 text-primary" role="status" aria-hidden="true"></span>
                                </div>
                                <iframe 
                                    width="100%"
                                    height="800px"
                                    frameborder="0"
                                    :src="iframeSrc" 
                                    id="preview_template"
                                >
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>

import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'
import Loading from '../reviews/loading.vue';

export default {
    name: 'PostTemplates',
    components: {
        vSelect,
        Loading,
    },
    data:function() {
        return {
            iframeSrc:'',
            isLoading:false,
            templates:[],
            defaultSettings:{},
            formFields:{
                template_id:'',
                backgroundcolor:'',
                schedule_type:'week',
                schedule_week_day:'',
                schedule_month_date:'',
                schedule_hour:'',
                status:'',
            },
            scheduleType:[
                {
                    label:'Monthly',
                    value:"month"
                },
                {
                    label:'Weekly',
                    value:"week"
                }
            ],
            weekDays:[
                {
                    label:'Sunday',
                    value:'sunday'
                },
                {
                    label:'Monday',
                    value:'monday'
                },
                {
                    label:'Tuesday',
                    value:'tuesday'
                },
                {
                    label:'Wednesday',
                    value:'wednesday'
                },
                {
                    label:'Thursday',
                    value:'thursday'
                },
                {
                    label:'Friday',
                    value:'friday'
                },
                {
                    label:'Saturday',
                    value:'saturday'
                }
            ],
            hours:[
                {
                    label:'12:00 AM',
                    value:'00:00'
                },
                {
                    label:'01:00 AM',
                    value:'01:00'
                },
                {
                    label:'02:00 AM',
                    value:'02:00'
                },
                {
                    label:'03:00 AM',
                    value:'03:00'
                },
                {
                    label:'04:00 AM',
                    value:'04:00'
                },
                {
                    label:'05:00 AM',
                    value:'05:00'
                },
                {
                    label:'06:00 AM',
                    value:'06:00'
                },
                {
                    label:'07:00 AM',
                    value:'07:00'
                },
                {
                    label:'08:00 AM',
                    value:'08:00'
                },
                {
                    label:'09:00 AM',
                    value:'09:00'
                },
                {
                    label:'10:00 AM',
                    value:'10:00'
                },
                {
                    label:'11:00 AM',
                    value:'11:00'
                },
                {
                    label:'12:00 PM',
                    value:'12:00'
                },
                {
                    label:'01:00 PM',
                    value:'13:00'
                },
                {
                    label:'02:00 PM',
                    value:'14:00'
                },
                {
                    label:'03:00 PM',
                    value:'15:00'
                },
                {
                    label:'04:00 PM',
                    value:'16:00'
                },
                {
                    label:'05:00 PM',
                    value:'17:00'
                },
                {
                    label:'06:00 PM',
                    value:'18:00'
                },
                {
                    label:'07:00 PM',
                    value:'19:00'
                },
                {
                    label:'08:00 PM',
                    value:'20:00'
                },
                {
                    label:'09:00 PM',
                    value:'21:00'
                },
                {
                    label:'10:00 PM',
                    value:'22:00'
                },
                {
                    label:'11:00 PM',
                    value:'23:00'
                }
            ],
            monthsDates:[],
            business_id:'',
            auto_post_status:'',
            review_note:'',
            totalFeeds:0
        }
    },
    created:async function() {
        
        /* $('body').on('click', 'div.template-card', function(){
            let that = $(this)
            $('body').find('div.template-card').removeClass('active')
            that.addClass('active')
        }) */

        $('body').on('click','a.preview_link', function(){
            $('body').find('#previewModal').modal('show')
        })

        this.fetchUserBusiness().then((resp) => {
            if(resp.status)
            {
                this.business_id = resp.data.id   
                this.auto_post_status = resp.data.auto_post_status   
            }
        })

        this.getPostTemplates().then((resp) => {
            if(resp)
            {
                this.templates = resp
            }
        })

        await this.getUserPostSetting().then( async (resp) => {
            if(resp)
            {
                this.formFields = {
                    id:resp.id,
                    template_id:resp.template_id,
                    backgroundcolor:resp.backgroundcolor,
                    status:resp.status,
                    schedule_type:resp.schedule_type,
                    schedule_hour:resp.schedule_hour,
                    schedule_month_date:resp.schedule_month_date,
                    schedule_week_day:resp.schedule_week_day
                }
                this.totalFeeds = resp.feedCount
                await this.getReviewPostNote(resp.schedule_week_day, resp.schedule_hour)
            }
        })

        let settingsField = [
            'default_review_post_template_id',
            'default_review_post_schedule_type',
            'default_review_post_schedule_hour',
            'default_review_post_background_color',
            'default_review_post_schedule_week_day',
            'default_review_post_schedule_month_date'
        ]

        this.getSetting(settingsField).then((resp) => {
            this.defaultSettings = resp
            if(!this.formFields.backgroundcolor)
            {
                this.formFields.backgroundcolor = this.defaultSettings.default_review_post_background_color
            }

            if(!this.formFields.template_id)
            {
                this.formFields.template_id = this.defaultSettings.default_review_post_template_id
            }

            if(!this.formFields.schedule_type)
            {
                this.formFields.schedule_type = this.defaultSettings.default_review_post_schedule_type
            }

            if(!this.formFields.schedule_week_day)
            {
                this.formFields.schedule_week_day = this.defaultSettings.default_review_post_schedule_week_day
            }

            if(!this.formFields.schedule_month_date)
            {
                this.formFields.schedule_month_date = this.defaultSettings.default_review_post_schedule_month_date
            }

            if(!this.formFields.schedule_hour)
            {
                this.formFields.schedule_hour = this.defaultSettings.default_review_post_schedule_hour
            }

            if(!this.review_note)
            {
                this.getReviewPostNote(this.formFields.schedule_week_day, this.formFields.schedule_hour)
            }

        })

        for (let index = 1; index <= 31; index++) {
            this.monthsDates.push(index)            
        }
    },
    validations: {
        formFields:{
            template_id:{
                required
            },
            backgroundcolor:{
                required
            },
            schedule_type:{
                required
            },
            status:{
                required
            },
            schedule_week_day:{},
            schedule_month_date:{},
            schedule_hour:{},
        }
    },
    methods: {
        ...mapActions([
            'getSetting',
            'getReviewNote',
            'getPostTemplate',
            'getPostTemplates',
            'getUserPostSetting',
            'updateUserPostSetting',
            'fetchUserBusiness',
            'updateUserBusinessPostStatus',
        ]),
        updatePostSettings:function(){
            this.isLoading = true
            this.updateUserPostSetting(this.formFields).then((resp) => {
                if(resp.status)
                {
                    this.formFields = {
                        id:resp.data.id,
                        template_id:resp.data.template_id,
                        backgroundcolor:resp.data.backgroundcolor,
                        status:resp.data.status,
                        schedule_type:resp.data.schedule_type,
                        schedule_hour:resp.data.schedule_hour,
                        schedule_month_date:resp.data.schedule_month_date,
                        schedule_week_day:resp.data.schedule_week_day
                    }
                    this.getReviewPostNote(this.formFields.schedule_week_day, this.formFields.schedule_hour)
                    this.notify(resp.message)   
                }
                else
                {
                    this.notify(resp && resp.message ? resp.message : 'Something went wrong please try later', 'danger')   
                }
                this.isLoading = false
            })
        },
        updatePostStatus:function(e){
            let checked = e.target.checked
            this.updateUserBusinessPostStatus({
                id:this.business_id,
                auto_post_status:this.auto_post_status,
            }).then((resp) => {
                if(resp.status)
                {
                    if(resp.business)
                    {
                        if(resp.business.auto_post_status)
                        {
                            this.getReviewPostNote(this.formFields.schedule_week_day, this.formFields.schedule_hour)
                        }
                    }   
                }
            })
        },
        notify: function(msg, type = 'success'){
            this.$toasted.show(msg, {
                theme: 'bubble',
                type: type,
                position: 'top-center',
                duration: 2500,
                singleton: true
            })
        },
        templatePreview:function(id){
            let iframe       = $('iframe#preview_template') 
            let iframeLoader = $('.iframe-loader');
            
            iframe.css('opacity',0);
            iframeLoader.removeClass('d-none')
            iframe.on('load',function(){
                setTimeout(function(){
                    iframe.css('opacity',1);
                    iframeLoader.addClass('d-none')
                },500)
            })
            this.iframeSrc = '';
            let url = process.env.VUE_APP_BASEURL
            let bgColor = this.formFields.backgroundcolor
            bgColor = bgColor ? bgColor.replace('#','') : '';
            this.iframeSrc = `${url}/review-to-image/preview?templateId=${id}&bgColor=${bgColor}&flag=${Math.random()}`;
            
        },
        getReviewPostNote:function(weekday, time){
            this.getReviewNote({weekday,time})
            .then((resp) => {
                if(resp.status)
                {
                    this.review_note = resp.note
                }
                else
                {
                    this.review_note = '';
                }
            })
        }

    }
}
</script>

<style>
body {
    background-color: #f9f9fa;
}

.padding {
    padding: 3rem !important;
}

.user-card-full {
    overflow: visible !important;
    min-height: 300px;
}

.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    border: none;
    margin-bottom: 30px;
}

.m-r-0 {
    margin-right: 0px;
}

.m-l-0 {
    margin-left: 0px;
}

@media (min-width: 992px) {
    .user-card-full .user-profile-div {
        border-radius: 5px 0 0 5px;
    }
}

.bg-c-lite-green {
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(#f29263),
        to(#ee5a6f)
    );
    background: linear-gradient(to right, #ee5a6f, #f29263);
}

.user-profile {
    padding: 20px 0;
}

.card-block {
    padding: 1.25rem;
}

.m-b-25 {
    margin-bottom: 25px;
}

.img-radius {
    border-radius: 5px;
}

h6 {
    font-size: 14px;
}

.card .card-block p {
    line-height: 25px;
}

@media only screen and (min-width: 1400px) {
    p {
        font-size: 14px;
    }
}

.card-block {
    padding: 1.25rem;
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0;
}

.m-b-20 {
    margin-bottom: 20px;
}

.p-b-5 {
    padding-bottom: 5px !important;
}

.card .card-block p {
    line-height: 25px;
}

.text-muted {
    color: #919aa3 !important;
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0;
}

.f-w-600 {
    font-weight: 600;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-t-40 {
    margin-top: 20px;
}

.p-b-5 {
    padding-bottom: 5px !important;
}

.m-t-40 {
    margin-top: 20px;
}

.user-card-full .social-link li {
    display: inline-block;
}

.user-card-full .social-link li a {
    font-size: 20px;
    margin: 0 10px 0 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.required {
    color: red;
}
.popup-logo {
    max-height: 120px;
    image-rendering: -webkit-optimize-contrast;
    max-width: 100px;
    height: auto;
    width: auto;
    top: 6px;
    border: solid;
}
.selected-logo {
    object-fit: contain;
    max-width: 100% !important;
    max-height: 149px;
    min-width: 149px;
    min-height: 149px;
}

.template-card{
    padding:1%;
    width: 200px;
}
.template-card.active{
    border-color: black;
    border-width: 3px;
    border-style: solid;
}

div.month_day_select ul,
div.hour_select ul {
    height: 200px !important;
}

.preview_link {
    position: absolute;
    top: 105%;
    left: 40%;
}

.selected-text {
    position: absolute;
    top: -10%;
    left: 42%;
}

.template-div {
    position: relative;
}
</style>