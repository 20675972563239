import { getRequest, postRequest } from '@/helper'

let reviewsModule = {
    state:{
        reviews: {},
        review: []
    },
    getters:{
        reviews: (state) => {
            return state.reviews
        },
        review: (state) => {
            return state.review
        }
    },
    actions:{
        getUserReviews: async ({ commit }, {page = 1, limit = '',field = '' ,direction = '', search = '', platform = ''}) => {
            platform = platform == null ? '' : platform;
            
            let url = `users/reviews?page=${page}&limit=${limit}&field=${field}&direction=${direction}&search=${search}&platform=${platform}`;
            let resp = await getRequest(url)
            if(resp && resp.status)
            {
                commit('setReviews', resp.data)
                return {
                    resp : resp.data,
                    fullResp:resp
                }
            }
            else
            {
                console.log(resp.message)
                console.error('Data not found')
            }
        },
        getReviews: async ({ commit }, {page = 1, limit = '', field = '' , direction = '', search = '', user_id = '', tag_id = '', smart_tag_id = '', rating = '', platform = '', status = '', short_description = '' }) => {
            user_id = user_id == null ? '' : user_id;
            tag_id =  tag_id == null ? '' : tag_id;
            smart_tag_id = smart_tag_id == null ? '' : smart_tag_id;
            status = status == null ? '' : status;
            rating = rating == null ? '' : rating;
            platform = platform == null ? '' : platform;
            short_description = short_description == null && short_description == '' ? '' : short_description;
            
            let url = `reviews?page=${page}&limit=${limit}&field=${field}&direction=${direction}&search=${search}&user_id=${user_id}&tag_id=${tag_id}&smart_tag_id=${smart_tag_id}&status=${status}&rating=${rating}&platform=${platform}&short_description=${short_description}`;
            let resp = await getRequest(url)
            if(resp && resp.status)
            {
                commit('setReviews', resp.data)
                return {
                    resp : resp.data,
                    fullResp:resp
                }
            }
            else
            {
                console.log(resp.message)
                console.error('Data not found')
            }
        },
        getReview: async ({ commit }, id) => {
            let resp = await getRequest(`reviews/view/${id}`)
            commit('setReview', resp.data)
            return resp
        },
        addReview: async({commit}, data) => {
            let {business_id, user_id, author , text, rating, avatar} = data
            let resp = await postRequest(`reviews/add`, {business_id, user_id, author , text, rating, avatar})
            return resp;
        },
        updateReview: async({commit}, data) => {
            let {id, text, short_description, status} = data
            let resp = await postRequest(`reviews/${id}/edit`, {id, text, short_description, status})
            return resp;
        },
        deleteReview: async ({ commit }, id) => {
            let resp = await getRequest(`reviews/${id}/delete`)
            if(resp && resp.status)
            {
                return resp
            }
            else
            {
                console.log(resp.message)
                console.error('Data not found')
            }
        },
        updateReviewStatus: async({commit}, data) => {
            let {id, status } = data
            let resp = await postRequest(`reviews/${id}/update-status`, {status})
            return resp;
        },
        tagMatchAction: async({commit}, data) => {
            let {action, review_id, type, tag_id, smart_tag_id } = data
            let resp = await postRequest(`tags-reviews-relation/${action}/make-action`, {review_id, type, tag_id, smart_tag_id})
            return resp;
        },
        userTagMatchAction: async({commit}, data) => {
            let {action, review_id, type, tag_id, smart_tag_id } = data
            let resp = await postRequest(`users/tags-reviews-relation/${action}/match-action`, {review_id, type, tag_id, smart_tag_id})
            return resp;
        },
        autoAsignTags: async({commit}, id) => {
            let resp = await getRequest(`tags-reviews-relation/auto-asign-tags/${id}`)
            return resp;
        },
        deleteUserReview: async ({ commit }, id) => {
            let resp = await getRequest(`users/reviews/${id}/delete`)
            return resp
        },
        updateUserReview: async({commit}, data) => {
            let {id, author, platform, rating, text, short_description} = data
            let resp = await postRequest(`users/reviews/${id}/update`, {author, text, short_description, platform, rating})
            return resp;
        },
        addUserReview: async({commit}, data) => {
            let {author, text, rating, platform, short_description} = data
            let resp = await postRequest(`users/reviews/add`, {author, text, rating, platform, short_description})
            return resp;
        },
        getUserReviewsWithTags: async ({ commit }, tags) => {    
            let url = `users/reviews/with-tags?tags=${tags}`;
            let resp = await getRequest(url)
            return resp
        },
        genrateReviewShortDescription: async ({ commit }, id) => {
            let resp = await getRequest(`reviews/${id}/genrate-short-description`)
            return resp
        },
    },
    mutations:{
        setReviews: (state, data) => {
            return state.reviews = data
        },
        setReview: (state, data) => {
            return state.review = data
        }
    }
}

export default reviewsModule